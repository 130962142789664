import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import validationSchemas from "../../utils/validations/validationSchemas";
import CustomBtn from "../../components/CustomBtn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MessageModal from "../../shared/loader/MessageModal";
import { setMessage } from "../../features/appSlice";
import CustomInput from "../../components/CustomInput";
import NumberInput from "../../components/NumberInput";
import NormalDropDown from "../../components/NormalDropDown";
import { ToastContainer, toast } from "react-toastify";
import {
  createNewAdRequest,
  setSingleAd,
  updateAdRequest,
} from "../../features/adSlice";
import { getDateFromISO } from "../../utils/helpers/helper";
import { adsAPI } from "../../api/adsAPI";

const AddNewManager = (props) => {
  let todayDate = new Date().toISOString().split("T")[0];
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.app.message);
  const loading = useSelector((state) => state.app.isLoading);
  const submitError = useSelector((state) => state.managers.hasError);
  const singleAd = useSelector((state) => state.ads.singleAd);
  const [touch1, setTouch1] = useState(false);
  const [touch2, setTouch2] = useState(false);
  const [touch3, setTouch3] = useState(false);
  const [adNumber, setAdNumber] = useState(false);
  const [number] = useState(() => {
    let array = [];
    for (let i = 1; i < 80; i++) {
      array.push(i);
    }
    return array;
  });
  const [page, setPage] = useState("");
  const [status, setStatus] = useState(
    props.edit ? (singleAd.status === "Y" ? "active" : "disabled") : ""
  );

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/ads-list");
  };

  const onCreate = (values) => {
    if (!status) return toast("Please select Ad status");
    if (!page) return toast("Please select Page where Ad will appear");
    if (!adNumber) return toast("Please select Ad number");
    if (
      values.whatsApp.toString().length < 10 ||
      values.whatsApp.toString().length > 10
    )
      return toast("10 Digits WhatsApp required");
    let dataToSend = {
      ...values,
      status: status === "active" ? "Y" : "N",
      adNumber,
      onPageAdShow: page,
    };
    if (props.edit) {
      let dataWithId = { ...dataToSend, id: singleAd._id };
      dispatch(updateAdRequest(dataWithId));
    } else {
      dispatch(createNewAdRequest(dataToSend));
    }
  };
  useEffect(() => {
    if (props.edit) {
      async function getSingleAd() {
        const single = await adsAPI.singleAd(id);
        setPage(single.onPageAdShow);
        setAdNumber(single.adNumber);
        dispatch(setSingleAd(single));
      }
      getSingleAd();
    }
  }, [dispatch, props.edit, id]);

  React.useEffect(() => {
    if (message) {
      setTimeout(() => {
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!message ? (
        <div
          style={{ minHeight: "80vh" }}
          className="mt-4 rounded bg-secoundry shadow-dhruv dark:bg-darkmodeSecoundry "
        >
          <div className="pb-8 ">
            <h2 className="px-4 pt-4 text-lg font-semibold text-blue-500 lg:ml-4 dark:text-darkmodeFots">
              {props.edit ? "Update Ad" : "Create Ad"}
            </h2>
            <ToastContainer />
            <Formik
              enableReinitialize
              initialValues={{
                ownerName: (props.edit && singleAd.ownerName) || "",
                adsTitle: (props.edit && singleAd.adsTitle) || "",
                mobile: (props.edit && singleAd.mobile) || 0,
                whatsApp: (props.edit && singleAd.whatsApp) || 0,
                validFromDate:
                  (props.edit &&
                    getDateFromISO(singleAd.validFromDate, true)) ||
                  todayDate,
                validToDate:
                  (props.edit && getDateFromISO(singleAd.validToDate, true)) ||
                  todayDate,
                adContent: (props.edit && singleAd.adContent) || "",
              }}
              validationSchema={validationSchemas.adValidation}
              onSubmit={onCreate}
            >
              {({ errors, touched }) => (
                <Form autoComplete="off">
                  <div
                    className={`flex justify-start md:flex-row flex-col items-center pt-4 `}
                  >
                    <div className="w-full px-2 lg:w-1/3 lg:px-4 lg:pl-6">
                      <div className={`px-2 w-full`}>
                        <CustomInput
                          errors={errors.adsTitle}
                          touched={touched.adsTitle}
                          title="Ad Title"
                          name="adsTitle"
                          placeholder="Enter Ad Title"
                        />
                      </div>
                    </div>
                    <div className="w-full px-4 lg:w-1/4">
                      <div className={` w-full`}>
                        <CustomInput
                          errors={errors.ownerName}
                          touched={touched.ownerName}
                          title="Ad Owner Name"
                          name="ownerName"
                          placeholder="OwnerName"
                        />
                      </div>
                    </div>
                    <div className="w-full px-2 lg:w-1/5 lg:px-0">
                      <div className={`px-2 w-full`}>
                        <NumberInput
                          errors={errors.mobile}
                          touched={touched.mobile}
                          title="Mobile"
                          name="mobile"
                          placeholder="Enter Mobile Number"
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-1/5">
                      <div className={`px-4 w-full`}>
                        <NumberInput
                          errors={errors.whatsApp}
                          touched={touched.whatsApp}
                          title="10 digits WhatsApp"
                          name="whatsApp"
                          placeholder="Enter WhatsApp"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`flex justify-start md:flex-row flex-col items-center lg:pt-4 `}
                  >
                    <div className="w-full mb-4 lg:w-2/3 lg:px-4">
                      <div className={`px-4 w-full`}>
                        <div>
                          <div className="mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                            Valid from Date
                          </div>
                          <div className="text-center ">
                            <Field
                              name="validFromDate"
                              type="date"
                              className={`${
                                errors.validFromDate && touched.validFromDate
                                  ? "border-red-500"
                                  : "border-gray-300"
                              }   outline-none  dark:text-gray-300 border rounded py-2 font-normal transition-all duration-300 focus:border-indigo-500 dark:border-gray-500 px-3 w-full text-sm text-gray-700 caret-indigo-600 dark:bg-darkmodeSecoundry `}
                            />
                          </div>
                          {errors.validFromDate && touched.validFromDate ? (
                            <div className="pl-4 text-xs text-red-500 ">
                              {errors.validFromDate}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="w-full mb-4 lg:w-2/3 lg:px-4">
                      <div className={`px-4 w-full`}>
                        <div>
                          <div className="mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                            Valid To Date
                          </div>
                          <div className="text-center ">
                            <Field
                              name="validToDate"
                              type="date"
                              className={`${
                                errors.validToDate && touched.validToDate
                                  ? "border-red-500"
                                  : "border-gray-300"
                              }   outline-none  dark:text-gray-300 border rounded py-2 font-normal transition-all duration-300 focus:border-indigo-500 dark:border-gray-500 px-3 w-full text-sm text-gray-700 caret-indigo-600 dark:bg-darkmodeSecoundry `}
                            />
                          </div>
                          {errors.validToDate && touched.validToDate ? (
                            <div className="pl-4 text-xs text-red-500 ">
                              {errors.validToDate}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="w-full px-4 mb-5 lg:w-2/3 ">
                      <NormalDropDown
                        text="sm" // text-xl text-xs
                        width="w-full" // w-48 , w-1/2 w-full
                        name="select" // name
                        array={number} // array to iterate
                        py="[6px]" // padding top & bottom
                        touch={touch3} // touch
                        setTouch={setTouch3} // set
                        label={"Ad Number"}
                        placeholderText="Enter Ad Number"
                        defaultValue="Ad Number"
                        value={adNumber} //
                        setValue={setAdNumber} //
                      />
                    </div>
                    <div className="w-full px-4 mb-5 lg:w-2/3">
                      <NormalDropDown
                        text="sm" // text-xl text-xs
                        width="w-full" // w-48 , w-1/2 w-full
                        name="select" // name
                        array={["home", "chart"]} // array to iterate
                        py="[6px]" // padding top & bottom
                        touch={touch1} // touch
                        setTouch={setTouch1} // set
                        label={"Page Ad Show"}
                        placeholderText="Status"
                        defaultValue="Select Status"
                        value={page}
                        setValue={setPage}
                      />
                    </div>
                    <div className="w-full px-4 mb-5 lg:w-2/3 ">
                      <NormalDropDown
                        text="sm" // text-xl text-xs
                        width="w-full" // w-48 , w-1/2 w-full
                        name="select" // name
                        array={["active", "disabled"]} // array to iterate
                        py="[6px]" // padding top & bottom
                        touch={touch2} // touch
                        setTouch={setTouch2} // set
                        label={"Status"}
                        placeholderText="Status"
                        defaultValue="Select Status"
                        value={status} //
                        setValue={setStatus} //
                      />
                    </div>
                  </div>

                  <div className="w-full pl-4 pr-4 lg:pl-8">
                    <h3 className="mb-1 text-sm font-medium text-gray-600 ">
                      Ad Content
                    </h3>
                    {/* <CKEditor
                      name="description"
                      editor={ClassicEditor}
                      data={text}
                      onBlur={handleBlogDataChange}
                      className="h-full"
                    /> */}
                    <Field
                      placeholder="Enter Ad Content"
                      as="textarea"
                      name="adContent"
                      className={`${
                        errors.adContent && touched.adContent
                          ? "border-red-500"
                          : "border-gray-300"
                      }   outline-none min-h-[10rem]  border rounded py-2 font-normal transition-all duration-300 focus:border-indigo-500 dark:border-gray-500 px-3 w-full text-sm text-gray-700 caret-indigo-600 dark:bg-darkmodeSecoundry`}
                    />
                  </div>
                  <div className="px-3 py-4">
                    <CustomBtn
                      type="submit"
                      text={props.edit ? "Update Ad" : "Create Ad"}
                      loading={loading}
                    />
                  </div>
                  {submitError && (
                    <div className="p-3 pl-8 text-red-500">{submitError}</div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <MessageModal
          open={message}
          setOpen={clearNotificationMessage}
          message={message}
        />
      )}
    </>
  );
};

export default AddNewManager;
