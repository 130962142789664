import React from "react";
import PillPart from "./PillPart";

const Pills = (props) => {
  return (
    <div
      className={`grid gap-4 ${
        props.cols ? props.cols : "lg:grid-cols-4 grid-cols-1"
      } grid-flow-row items-center py-4 px-4`}
    >
      {props.content &&
        props.content.length > 0 &&
        props.content.map((item, index) => {
          return (
            <PillPart
              id={item._id}
              deleteString={props.deleteString}
              updateString={props.updateString}
              property={item[props.property]}
              index={index}
              key={index}
            />
          );
        })}
    </div>
  );
};

export default Pills;
