import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import validationSchemas from "../../utils/validations/validationSchemas";
import CustomBtn from "../../components/CustomBtn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MessageModal from "../../shared/loader/MessageModal";
import { setMessage } from "../../features/appSlice";
import { reverseString } from "../../utils/helpers/helper";
import ToastNotification from "../../shared/ToastNotification";
import NumberInput from "../../components/NumberInput";
import {
  publishResultRequest,
  singleResultRequest,
  updateResultRequest,
} from "../../features/resultSlice";
import { gameAPI } from "../../api/gameAPI";
import DropdownWithSearch from "../../components/DropdownWithSearch";

// gameName, resultDate, result
const PublishNewResult = (props) => {
  let todayDate = new Date().toISOString().split("T")[0];
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.app.message);
  const loading = useSelector((state) => state.app.isLoading);
  const submitError = useSelector((state) => state.results.hasError);
  const singleResult = useSelector((state) => state.results.singleResult);
  const [touch, setTouch] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [gameName, setGameName] = useState(
    props.edit ? singleResult.gameName : ""
  );
  const [gamesList, setGameList] = useState([]);

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/results");
  };

  const onCreate = (values) => {
    if (!gameName) return setErrorMessage("Game name is required");
    if (values.result > 100) return setErrorMessage("Max number can be 100");
    if (values.result < 0)
      return setErrorMessage("Result can not be less than Zero");
    let allValues = {
      resultDate: reverseString(values.resultDate),
      result: values.result === 100 ? 0 : values.result,
      gameName: gameName,
    };
    if (props.edit) {
      let dataWithId = { ...allValues, id: singleResult._id };
      dispatch(updateResultRequest(dataWithId));
    } else {
      dispatch(publishResultRequest(allValues));
    }
  };
  useEffect(() => {
    if (props.edit) {
      dispatch(singleResultRequest(id));
    }
  }, [dispatch, props.edit, id]);

  useEffect(() => {
    async function getAllGames() {
      let allGames = await gameAPI.getAllGames();
      let gameArray =
        allGames && allGames.length > 0 && allGames.map((i) => i.gameName);
      setGameList(gameArray);
    }
    getAllGames();
  }, []);
  React.useEffect(() => {
    if (message) {
      setTimeout(() => {
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!message ? (
        <div
          style={{ minHeight: "80vh" }}
          className="mt-4   bg-secoundry shadow-dhruv rounded dark:bg-darkmodeSecoundry "
        >
          <ToastNotification
            message={errorMessage}
            open={errorMessage}
            setOpen={() => setErrorMessage("")}
          />
          <div className="pb-8">
            <h2 className=" font-semibold px-4 text-lg ml-4 pt-4 text-blue-500 dark:text-darkmodeFots ">
              {props.edit ? "Update Result" : "Publish Result"}
            </h2>
            <Formik
              enableReinitialize
              initialValues={{
                resultDate:
                  (props.edit && reverseString(singleResult.resultDate)) ||
                  todayDate,
                result: (props.edit && singleResult.result) || 0,
              }}
              validationSchema={validationSchemas.resultValidation}
              onSubmit={onCreate}
            >
              {({ errors, touched }) => (
                <Form autoComplete="off">
                  <div
                    className={`flex justify-start items-center md:flex-row flex-col pt-4 px-4 `}
                  >
                    <div className="lg:w-2/3 w-full  px-4 mb-5 ">
                      <DropdownWithSearch
                        text="sm" // text-xl text-xs
                        width="w-full" // w-48 , w-1/2 w-full
                        name="select" // name
                        array={gamesList} // array to iterate
                        py="[6px]" // padding top & bottom
                        touch={touch} // touch
                        setTouch={setTouch} // set
                        label={"Game Name"}
                        placeholderText="Select Game"
                        defaultValue="Select Game"
                        value={gameName}
                        setValue={setGameName}
                      />
                    </div>
                    <div className="lg:w-2/3 w-full px-2">
                      <div className={`px-2 w-full`}>
                        <NumberInput
                          errors={errors.result}
                          touched={touched.result}
                          title="Result"
                          name="result"
                          max={99}
                          placeholder="Enter Result"
                        />
                      </div>
                    </div>
                    <div className="lg:w-2/3 w-full lg:px-4 mb-4">
                      <div className={`px-4 w-full`}>
                        <div>
                          <div className="  text-gray-600 font-medium text-sm mb-1 dark:text-gray-300">
                            Result Date
                          </div>
                          <div className="text-center ">
                            <Field
                              name="resultDate"
                              type="date"
                              className={`${
                                errors.resultDate && touched.resultDate
                                  ? "border-red-500"
                                  : "border-gray-300"
                              }   outline-none  dark:text-gray-300 border rounded py-2 font-normal transition-all duration-300 focus:border-indigo-500 dark:border-gray-500 px-3 w-full text-sm text-gray-700 caret-indigo-600 dark:bg-darkmodeSecoundry `}
                            />
                          </div>
                          {errors.resultDate && touched.resultDate ? (
                            <div className=" text-red-500 text-xs pl-4">
                              {errors.resultDate}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="px-3 py-4">
                    <CustomBtn
                      type="submit"
                      text={props.edit ? "Update Result" : "Publish Result"}
                      loading={loading}
                    />
                  </div>
                  {submitError && (
                    <div className="p-3 pl-8 text-red-500">{submitError}</div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <MessageModal
          open={message}
          setOpen={clearNotificationMessage}
          message={message}
        />
      )}
    </>
  );
};

export default PublishNewResult;
