import React, { useState, useEffect } from "react";
import decode from "jwt-decode";
import SideBar from "./SideBar";
import Header from "./Header";
import { setAuthorizationToken } from "../../utils/helpers/setAuthorizationToken";
import { authenticationStatus } from "../../features/loginSlice";
import { userRequest } from "../../features/adminUserSlice";
import { setDropDown, setPills } from "../../features/appSlice";
import { useDispatch, useSelector } from "react-redux";
import AllRoutes from "../routes/AllRoutes";
import { useNavigate } from "react-router-dom";

const Layout = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  const userID = useSelector((state) => state.user.userDetails);
  const showDropDown = useSelector((state) => state.app.showDropDown);
  const pillsDropDown = useSelector((state) => state.app.showPills);
  const token = localStorage.getItem("panelToken");
  const [darkMode, setDarkMode] = useState(false);
  const [show, setShow] = useState(true);
  const [profileDropDown, setProfileDropDown] = useState(false);
  const [hamburgerMenu, setHamburgerMenu] = useState(false);

  const darkModeHandler = () => {
    const root = window.document.documentElement; //add 'dark class to html root element'
    root.classList.toggle("dark");
    setDarkMode((prevState) => !prevState);
  };

  useEffect(() => {
    if (!token) {
      setAuthorizationToken(false);
      dispatch(authenticationStatus(false));
      navigate("/");
      return;
    }
    if (token) {
      const decodedToken = decode(token);
      if (!decodedToken) {
        localStorage.removeItem("panelToken");
        setAuthorizationToken(false);
        navigate("/");
        return;
      }
      if (decodedToken.exp * 1000 > Date.now()) {
        setAuthorizationToken(JSON.parse(token));
        dispatch(authenticationStatus(true));
        !userID?._id && dispatch(userRequest());
        return;
      }
    }
    // eslint-disable-next-line
  }, [token, dispatch, userID._id]);

  const dropDownHide = () => {
    profileDropDown && setProfileDropDown(false);
    showDropDown && dispatch(setDropDown(false));
    pillsDropDown && dispatch(setPills(false));
  };

  return (
    <main
      onClick={dropDownHide}
      className="container mx-auto bg-primary dark:bg-darkmodePrimary lg:max-w-8xl"
    >
      {isAuthenticated && (
        <SideBar
          darkMode={darkMode}
          show={show}
          setHamburgerMenu={setHamburgerMenu}
          hamburgerMenu={hamburgerMenu}
          toggleSidebar={() => {
            setShow((prev) => !prev);
          }}
        />
      )}
      {isAuthenticated ? (
        <div
          className={` ${
            show ? "lg:ml-[9.4rem] lg:mr-2" : "lg:ml-[4.2rem] lg:mr-2"
          } `}
        >
          <Header
            darkMode={darkMode}
            profileDropDown={profileDropDown}
            setProfileDropDown={setProfileDropDown}
            show={show}
            darkModeHandler={darkModeHandler}
            setHamburgerMenu={setHamburgerMenu}
            hamburgerMenu={hamburgerMenu}
          />

          <div
            onClick={() => {
              hamburgerMenu && setHamburgerMenu(false);
            }}
            className={`${
              !show ? "lg:w-[100vw-9.4rem] mr-2" : "lg:w-[100vw-4.2rem] lg:mr-2"
            } dark:bg-darkmodePrimary bg-primary pb-4 mx-auto min-h-screen relative `}
          >
            <AllRoutes show={show} />
          </div>
        </div>
      ) : (
        <AllRoutes show={show} />
      )}
    </main>
  );
};

export default Layout;
