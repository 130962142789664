import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export const faqsAPI = {
  getAllFaqs: async () => {
    return axios.get(`${baseURL}/faqs`).then((res) => res.data);
  },
  sendUrlToGoogle: async (links) => {
    return await axios
      .post(`${baseURL}/url-list`, links)
      .then((res) => res.data);
  },
  createNewFaq: async (article) => {
    return await axios.post(`${baseURL}/faqs`, article).then((res) => res.data);
  },
  singleFaq: async (id) => {
    return await axios.get(`${baseURL}/faqs/${id}`).then((res) => res.data);
  },
  updateFaq: async (details) => {
    const { id, ...rest } = details;
    return await axios
      .put(`${baseURL}/faqs/${id}`, rest)
      .then((res) => res.data);
  },
  deleteFaq: async (id) => {
    return await axios.delete(`${baseURL}/faqs/${id}`).then((res) => res.data);
  },
};
