import axios from "axios";

export const blogsAPI = {
  getBlogsfromLast24Hours: async () => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/last-24-hours-blogs`)
      .then((res) => res.data);
  },
  allBlogsCount: async () => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/all-blogs-count`)
      .then((res) => res.data);
  },

  getAllBlogs: async () => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/blogs`)
      .then((res) => res.data);
  },
  createNewBlog: async (article) => {
    return await axios
      .post(`${process.env.REACT_APP_API_URL}/create-post`, article)
      .then((res) => res.data);
  },
  singleBlog: async (id) => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/blogs/${id}`)
      .then((res) => res.data);
  },
  updateBlog: async (details) => {
    const { id, ...rest } = details;
    return await axios
      .put(`${process.env.REACT_APP_API_URL}/blogs/${id}`, rest)
      .then((res) => res.data);
  },
  deleteBlog: async (id) => {
    return await axios
      .delete(`${process.env.REACT_APP_API_URL}/blogs/${id}`)
      .then((res) => res.data);
  },
};
