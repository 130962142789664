import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";

const Badge = (props) => {
  const [showEdit, setEdit] = useState(false);
  return (
    <h4
      onMouseEnter={() => {
        !showEdit && setEdit(true);
      }}
      onMouseLeave={() => {
        showEdit && setEdit(false);
      }}
      key={props.index}
      class="text-xs relative inline-block py-2 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-green-600 text-white rounded-full"
    >
      {props.property}
      {showEdit && (
        <div className="flex items-center absolute left-1/3 top-1">
          <Link to={`${props.updateString}/${props.id}`}>
            <p className="p-1 bg-white hover:bg-white text-center rounded-full">
              <FaEdit
                title="Update"
                className="text-[10px]  text-gray-600  hover:text-green-500 dark:text-darkmodeFots"
              />
            </p>
          </Link>
          <p className="mx-2"></p>
          <Link to={`${props.deleteString}/${props.id}`}>
            <p className="p-1 bg-white hover:bg-white text-center rounded-full">
              <BsTrash
                title="Delete"
                className="text-[10px]  text-gray-600  hover:text-red-500 dark:text-darkmodeFots"
              />
            </p>
          </Link>
        </div>
      )}
    </h4>
  );
};

export default Badge;
