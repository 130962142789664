import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { BsPerson } from "react-icons/bs";
import { CgDollar } from "react-icons/cg";
import { FaUserNinja } from "react-icons/fa";
import {
  getBlogsCount,
  latest24HoursBlogRequest,
} from "../../features/blogSlice";
import { getAllGamesRequest } from "../../features/gameSlice";
import { getAllManagersRequest } from "../../features/managerSlice";

const TopCompo = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userDetails);
  const blogCount = useSelector((state) => state.blogs.blogsLength);
  const gamesCount = useSelector((state) => state.games.gamesList);
  const totalManagers = useSelector((state) => state.managers.managersList);
  const latestBlogs = useSelector((state) => state.blogs.latestBlogs);

  useEffect(() => {
    dispatch(getBlogsCount());
    dispatch(getAllGamesRequest());
    dispatch(getAllManagersRequest());
    dispatch(latest24HoursBlogRequest());
  }, [dispatch]);

  return (
    <div className="grid lg:grid-cols-3 grid-cols-1 lg:grid-flow-col grid-flow-row lg:gap-3 pb-4  items-center w-full mt-4">
      <div className="bg-secoundry dark:bg-darkmodeSecoundry shadow-dhruv pl-4 py-[18px] w-full ">
        <h3 className=" text-iconColor text-sm dark:text-darkmodeFots">
          Congratulations 🎉{" "}
          {user !== undefined && user.name && (
            <span className="text-indigo-600">{user.name}!</span>
          )}
        </h3>
        <h4 className=" text-iconColor text-xs py-3 dark:text-darkmodeFots">
          New Blogs in last 24 hours
        </h4>
        <Link to="/blogs">
          <p className=" font-medium text-botton text-xl hover:underline">
            {latestBlogs ? latestBlogs.length : 0}
          </p>
        </Link>
      </div>
      <div className="bg-secoundry dark:bg-darkmodeSecoundry shadow-dhruv w-full col-span-2 p-2 py-5 px-3">
        <h3 className=" text-iconColor text-sm dark:text-darkmodeFots">
          Statistics
        </h3>
        <div className="mt-2 grid lg:grid-cols-3 grid-rows-3 lg:grid-rows-1  gap-2 w-full">
          <div className="  flex items-center">
            <div className=" bg-botton/80 rounded-full p-4">
              <FaUserNinja className=" text-secoundry dark:text-darkmodeFots text-xl" />
            </div>
            <div className=" font-semibold text-iconColor text-sm ml-4">
              <h3 className=" font-bold text-xl dark:text-darkmodeFots">
                {blogCount}
              </h3>
              <Link to="/blogs/complete-blogs-list">
                <h6 className="dark:text-darkmodeFots text-sm hover:underline hover:text-blue-600">
                  Total Blogs
                </h6>
              </Link>
            </div>
          </div>
          <div className=" flex items-center">
            <div className="bg-botton/80 rounded-full p-4">
              <BsPerson className=" text-secoundry text-2xl dark:text-darkmodeFots" />
            </div>
            <div className=" font-semibold text-iconColor text-sm ml-6 items-center justify-center">
              <h3 className=" font-bold text-xl dark:text-darkmodeFots ">
                {totalManagers ? totalManagers.length : 0}
              </h3>
              <h6 className="dark:text-darkmodeFots text-sm">Active Clients</h6>
            </div>
          </div>

          <div className=" flex items-center ">
            <div className=" bg-botton/80 rounded-full p-4">
              <CgDollar className=" text-secoundry text-2xl dark:text-darkmodeFots" />
            </div>
            <div className=" font-semibold text-iconColor text-sm ml-6 items-center justify-center">
              <h3 className=" font-bold text-xl dark:text-darkmodeFots">
                {gamesCount ? gamesCount.length : 0}
              </h3>
              <Link to="/games">
                <h6 className="dark:text-darkmodeFots hover:text-blue-600 hover:underline">
                  Total Games
                </h6>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopCompo;
