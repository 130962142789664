import React from "react";
import Badge from "./Badge";

const TagsBadge = (props) => {
  return (
    <div
      className={`grid gap-4 ${
        props.cols ? props.cols : "grid-cols-8"
      } grid-flow-row items-center py-4 px-4`}
    >
      {props.content &&
        props.content.length > 0 &&
        props.content.map((item, index) => {
          return (
            <Badge
              id={item._id}
              deleteString="delete-tag"
              updateString="update-tag"
              property={item[props.property]}
              index={index}
            />
          );
        })}
    </div>
  );
};

export default TagsBadge;
