import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteResultRequest } from "../../features/resultSlice";
import DeleteModal from "../../components/DeleteModal";

const DeleteManager = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const deletemessage = useSelector((state) => state.app.message);

  const deleteF = () => {
    dispatch(deleteResultRequest(id));
  };

  return (
    <DeleteModal
      message={deletemessage}
      cancelLink="/results"
      deleteFunction={deleteF}
    />
  );
};

export default DeleteManager;
