import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import validationSchemas from "../../utils/validations/validationSchemas";
import CustomBtn from "../../components/CustomBtn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MessageModal from "../../shared/loader/MessageModal";
import { setMessage } from "../../features/appSlice";
import CustomInput from "../../components/CustomInput";
import NormalDropDown from "../../components/NormalDropDown";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ToastContainer, toast } from "react-toastify";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  createNotificationRequest,
  singleNotificationRequest,
  updateNotificationRequest,
} from "../../features/notificationSlice";
import { notificationAPI } from "../../api/notificationAPI";

const CreateNotification = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.app.message);
  const loading = useSelector((state) => state.app.isLoading);
  const submitError = useSelector((state) => state.notifications.hasError);
  const singleNotification = useSelector(
    (state) => state.notifications.singleNotification
  );
  const [touch1, setTouch1] = useState(false);
  const [status, setStatus] = useState(
    props.edit
      ? singleNotification.status === "Y"
        ? "active"
        : "disabled"
      : ""
  );
  const [text, setAddData] = useState("");

  const handleBlogDataChange = (e, editor) => {
    const data = editor.getData();
    setAddData(data);
  };

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/notifications");
  };

  const onCreate = (values) => {
    if (!status) return toast("Please select Notification status");
    if (!text) return toast("Notification Content Required");
    let dataToSend = {
      ...values,
      status: status === "active" ? "Y" : "N",
      content: text,
    };
    if (props.edit) {
      let dataWithId = { ...dataToSend, id: singleNotification._id };
      dispatch(updateNotificationRequest(dataWithId));
    } else {
      dispatch(createNotificationRequest(dataToSend));
    }
  };

  useEffect(() => {
    if (props.edit) {
      async function singleNotificationGet() {
        const single = await notificationAPI.singleNotification(id);
        setAddData(single.content);
      }
      singleNotificationGet();
      dispatch(singleNotificationRequest(id));
    }
  }, [dispatch, props.edit, id]);

  React.useEffect(() => {
    if (message) {
      setTimeout(() => {
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!message ? (
        <div
          style={{ minHeight: "80vh" }}
          className="mt-4   bg-secoundry shadow-dhruv rounded dark:bg-darkmodeSecoundry "
        >
          <div className=" pb-8">
            <h2 className=" font-semibold px-4 text-lg lg:ml-4 pt-4 text-blue-500 dark:text-darkmodeFots ">
              {props.edit ? "Update Notification" : "Create Notification"}
            </h2>
            <ToastContainer />
            <Formik
              enableReinitialize
              initialValues={{
                title: (props.edit && singleNotification.title) || "",
              }}
              validationSchema={validationSchemas.notificationValidation}
              onSubmit={onCreate}
            >
              {({ errors, touched }) => (
                <Form autoComplete="off">
                  <div
                    className={`flex md:flex-row flex-col md:justify-start items-center pt-4 `}
                  >
                    <div className=" md:w-2/3 w-full px-4  md:pl-6">
                      <div className={`px-2 w-full`}>
                        <CustomInput
                          errors={errors.title}
                          touched={touched.title}
                          title="Title"
                          name="title"
                          placeholder="Enter Notification Title"
                        />
                      </div>
                    </div>
                    <div className="md:w-1/3 w-full  md:px-4 px-6 mb-5 ">
                      <NormalDropDown
                        text="sm" // text-xl text-xs
                        width="w-full" // w-48 , w-1/2 w-full
                        name="select" // name
                        array={["active", "disabled"]} // array to iterate
                        py="[6px]" // padding top & bottom
                        touch={touch1} // touch
                        setTouch={setTouch1} // set
                        label={"Status"}
                        placeholderText="Status"
                        defaultValue="Select Status"
                        value={status} //
                        setValue={setStatus} //
                      />
                    </div>
                  </div>

                  <div className="pl-4 md:pl-8 pr-4 w-full">
                    <h3 className=" text-gray-600 font-medium text-sm mb-1 ">
                      Content
                    </h3>
                    <CKEditor
                      name="description"
                      editor={ClassicEditor}
                      data={text}
                      onBlur={handleBlogDataChange}
                      className="h-full"
                    />
                  </div>
                  <div className="px-3 py-4">
                    <CustomBtn
                      type="submit"
                      text={
                        props.edit
                          ? "Update Notification"
                          : "Create Notification"
                      }
                      loading={loading}
                    />
                  </div>
                  {submitError && (
                    <div className="p-3 pl-8 text-red-500">{submitError}</div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <MessageModal
          open={message}
          setOpen={clearNotificationMessage}
          message={message}
        />
      )}
    </>
  );
};

export default CreateNotification;
