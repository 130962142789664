import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export const tagsAPI = {
  getAllTags: async () => {
    return axios.get(`${baseURL}/tags`).then((res) => res.data);
  },
  createNewTag: async (article) => {
    return await axios.post(`${baseURL}/tags`, article).then((res) => res.data);
  },
  singleTag: async (id) => {
    return axios.get(`${baseURL}/tags/${id}`).then((res) => res.data);
  },
  updateTag: async (details) => {
    const { id, ...rest } = details;
    return await axios
      .put(`${baseURL}/tags/${id}`, rest)
      .then((res) => res.data);
  },
  deleteTag: async (id) => {
    return await axios.delete(`${baseURL}/tags/${id}`).then((res) => res.data);
  },
};
