import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteNotificationRequest } from "../../features/notificationSlice";
import DeleteModal from "../../components/DeleteModal";

const DeleteManager = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const deletemessage = useSelector((state) => state.app.message);

  const deleteF = () => {
    dispatch(deleteNotificationRequest(id));
  };

  return (
    <DeleteModal
      message={deletemessage}
      cancelLink="/notifications"
      deleteFunction={deleteF}
    />
  );
};

export default DeleteManager;
