import {
  AiOutlineQuestionCircle,
  AiOutlineNumber,
  AiOutlineLineChart,
  AiOutlineFileDone,
} from "react-icons/ai";
import { BiHome, BiGame, BiTable } from "react-icons/bi";
import { BsFileSpreadsheet } from "react-icons/bs";
import { FiLogOut, FiUserPlus } from "react-icons/fi";
import { TbNumbers } from "react-icons/tb";
import {
  MdOutlineArticle,
  MdOutlineFileUpload,
  MdNotificationsNone,
} from "react-icons/md";

export const sideBarData = [
  {
    title: "Home",
    icon: (
      <BiHome className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/dashboard",
  },
  {
    title: "Notifications",
    icon: (
      <MdNotificationsNone className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/notifications",
  },
  {
    title: "Clients",
    icon: (
      <FiUserPlus className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/clients",
  },
  {
    title: "Tables",
    icon: (
      <BiTable className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/table-management",
  },
  {
    title: "Games",
    icon: (
      <BiGame className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/games",
  },

  {
    title: "Results",
    icon: (
      <TbNumbers className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/results",
  },
  {
    title: "Ads",
    icon: (
      <BsFileSpreadsheet className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/ads-list",
  },
  {
    title: "Chart",
    icon: (
      <AiOutlineLineChart className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/charts",
  },
  {
    title: "Blogs",
    icon: (
      <MdOutlineArticle className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/blogs",
  },

  {
    title: "Tags",
    icon: (
      <AiOutlineNumber className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/tags-list",
  },

  {
    title: "Content",
    icon: (
      <AiOutlineFileDone className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/terms-list",
  },

  {
    title: "FAQ's",
    icon: (
      <AiOutlineQuestionCircle className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/faqs-list",
  },

  {
    title: "Bulk +",
    icon: (
      <MdOutlineFileUpload className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/upload-workbook-with-results",
  },

  // {
  //   title: "SEO",
  //   icon: (
  //     <AiOutlineLineChart className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
  //   ),
  //   path: "/seo",
  // },
  // {
  //   title: "Upload",
  //   icon: (
  //     <MdOutlineFileUpload className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
  //   ),
  //   path: "/uploaded-web-story-images",
  // },
  {
    title: "Logout",
    icon: (
      <FiLogOut className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/logout",
  },
];
