import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import DashBoard from "../../pages/DashBoard";
import Login from "../../pages/LoginPages/LoginComp";
import Logout from "../../shared/loader/Logout";
import ManagersList from "../../pages/managers/ManagersList";
import AddNewManager from "../../pages/managers/AddNewManager";
import FaqList from "../../pages/faqs/FaqList";
import AdsList from "../../pages/ads/AdsList";
import DeleteAd from "../../pages/ads/DeleteAd";
import CreateNewAd from "../../pages/ads/CreateNewAd";
import LatestBlogsList from "../../pages/blogs/LatestBlogsList";
import Categories from "../../pages/categories/Categories";
import TableList from "../../pages/tables/TableList";
import GamesList from "../../pages/games/GamesList";
import AddTable from "../../pages/tables/AddTable";
import DeleteTable from "../../pages/tables/DeleteTable";
import TagsList from "../../pages/tags/TagsList";
import AddNewFaq from "../../pages/faqs/AddNewFaq";
import AddCategory from "../../pages/categories/AddCategory";
import DeleteCategory from "../../pages/categories/DeleteCategory";
import DeleteManager from "../../pages/managers/DeleteManager";
import DeleteFAQ from "../../pages/faqs/DeleteFAQ";
import CompleteBlogsList from "../../pages/blogs/CompleteBlogsList";
import AddTag from "../../pages/tags/AddTag";
import DeleteTag from "../../pages/tags/DeleteTag";
import TermsList from "../../pages/terms/TermsList";
import AddTerm from "../../pages/terms/AddTerm";
import DeleteTerm from "../../pages/terms/DeleteTerm";
import CreateBlog from "../../pages/blogs/CreateBlog";
import UpdateBlog from "../../pages/blogs/UpdateBlog";
import DeleteBlog from "../../pages/blogs/DeleteBlog";
// import AllWebStories from "../../pages/stories/AllWebStories";
// import LatestStories from "../../pages/stories/LatestStories";
// import CreateStory from "../../pages/stories/createStory/CreateStory";
// import PreviewStory from "../../pages/stories/createStory/PreviewWebStory";
// import DeleteStory from "../../pages/stories/DeleteStory";
// import SendUrlToGoogle from "../../pages/IndexAPI/SendUrlToGoogle";
// import CloudinaryImagesList from "../../pages/upload/CloudinaryImagesList";
// import DeleteImageFromCloudinary from "../../pages/upload/DeleteImageFromCloudinary";
// import UploadImage from "../../pages/upload/UploadImage";
import Resultslist from "../../pages/results/ResultsList";
import LatestResults from "../../pages/results/LatestResults";
import NotificationsList from "../../pages/notifications/NotificationsList";
import CreateNewNotification from "../../pages/notifications/CreateNewNotification";
import DeleteNotification from "../../pages/notifications/DeleteNotification";
import PublishNewResult from "../../pages/results/PublishNewResult";
import DeleteResult from "../../pages/results/DeleteResult";
import AddNewGame from "../../pages/games/AddNewGame";
import DeleteGame from "../../pages/games/DeleteGame";
import Charts from "../../pages/charts/Charts";
import AddGameToChart from "../../pages/charts/AddGameToChart";
import WorkSheetUpload from "../../pages/upload/WorkSheetUpload";

const AllRoutes = (props) => {
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route
        exact
        path="/dashboard"
        element={
          <PrivateRoute>
            <DashBoard show={props.show} />
          </PrivateRoute>
        }
      />

      {/* Clients Routes STARTS here */}
      <Route
        exact
        path="/clients"
        element={
          <PrivateRoute>
            <ManagersList />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/clients/add-new-client"
        element={
          <PrivateRoute>
            <AddNewManager />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/clients/update-client/:id"
        element={
          <PrivateRoute>
            <AddNewManager edit={true} />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/clients/delete-client/:id"
        element={
          <PrivateRoute>
            <DeleteManager />
          </PrivateRoute>
        }
      />
      {/* LClients Routes ENDS here */}
      {/* Blogs's Routes STARTS here */}
      <Route
        exact
        path="/blogs"
        element={
          <PrivateRoute>
            <LatestBlogsList />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/blogs/complete-blogs-list"
        element={
          <PrivateRoute>
            <CompleteBlogsList />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/blogs/create-new-blog"
        element={
          <PrivateRoute>
            <CreateBlog />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/blogs/update-blog/:id"
        element={
          <PrivateRoute>
            <UpdateBlog />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/blogs/delete-blog/:id"
        element={
          <PrivateRoute>
            <DeleteBlog />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/blogs/update-blog/:id"
        element={
          <PrivateRoute>
            <CreateBlog edit={true} />
          </PrivateRoute>
        }
      />
      {/* Blogs's Routes ENDS here */}

      {/* Web Stories Routes STARTS here */}
      {/* 
      <Route
        exact
        path="/web-stories"
        element={
          <PrivateRoute>
            <LatestStories />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/web-stories/add-new-story"
        element={
          <PrivateRoute>
            <CreateStory />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/web-stories/preview-story"
        element={
          <PrivateRoute>
            <PreviewStory />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/web-stories/all-web-stories"
        element={
          <PrivateRoute>
            <AllWebStories />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/web-stories/delete-story/:id"
        element={
          <PrivateRoute>
            <DeleteStory />
          </PrivateRoute>
        }
      /> */}

      {/* Web Stories Routes ENDS here */}

      {/* Results Routes STARTS here */}

      <Route
        exact
        path="/results"
        element={
          <PrivateRoute>
            <LatestResults />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/results-complete"
        element={
          <PrivateRoute>
            <Resultslist />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/results/publish-result"
        element={
          <PrivateRoute>
            <PublishNewResult />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/results/update-result/:id"
        element={
          <PrivateRoute>
            <PublishNewResult edit={true} />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/results/delete-result/:id"
        element={
          <PrivateRoute>
            <DeleteResult />
          </PrivateRoute>
        }
      />
      {/* LClients Routes ENDS here */}
      {/* Ads Routes STARTS here */}

      <Route
        exact
        path="/games"
        element={
          <PrivateRoute>
            <GamesList />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/games/add-new-game"
        element={
          <PrivateRoute>
            <AddNewGame />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/games/update-game/:id"
        element={
          <PrivateRoute>
            <AddNewGame edit={true} />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/games/delete-game/:id"
        element={
          <PrivateRoute>
            <DeleteGame />
          </PrivateRoute>
        }
      />

      {/* Ads Routes STARTS here */}
      {/* Chart Routes STARTS here */}

      <Route
        exact
        path="/charts"
        element={
          <PrivateRoute>
            <Charts />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/charts/update-chart/:id"
        element={
          <PrivateRoute>
            <AddGameToChart edit={true} />
          </PrivateRoute>
        }
      />

      {/* Chart Routes ENDS here */}

      <Route
        exact
        path="/ads-list"
        element={
          <PrivateRoute>
            <AdsList />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/ads-list/create-new-ad"
        element={
          <PrivateRoute>
            <CreateNewAd />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/ads-list/update-ad/:id"
        element={
          <PrivateRoute>
            <CreateNewAd edit={true} />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/ads-list/delete-ad/:id"
        element={
          <PrivateRoute>
            <DeleteAd />
          </PrivateRoute>
        }
      />

      {/* Ads Routes ENDS here */}

      {/* Notifications Routes STARTS here */}

      <Route
        exact
        path="/notifications"
        element={
          <PrivateRoute>
            <NotificationsList />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/notifications/create-new-notification"
        element={
          <PrivateRoute>
            <CreateNewNotification />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/notifications/update-notification/:id"
        element={
          <PrivateRoute>
            <CreateNewNotification edit={true} />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/notifications/delete-notification/:id"
        element={
          <PrivateRoute>
            <DeleteNotification />
          </PrivateRoute>
        }
      />

      {/* Notifications Routes ENDS here */}

      {/* Upload Excel with Results STARTS here */}
      <Route
        exact
        path="/upload-workbook-with-results"
        element={
          <PrivateRoute>
            <WorkSheetUpload />
          </PrivateRoute>
        }
      />

      {/* Upload Excel with Results ENDS here */}

      {/* Table Routes STARTS here */}

      <Route
        exact
        path="/table-management"
        element={
          <PrivateRoute>
            <TableList />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/table-management/create-new-table"
        element={
          <PrivateRoute>
            <AddTable />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/table-management/update-table/:id"
        element={
          <PrivateRoute>
            <AddTable edit={true} />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/table-management/delete-table/:id"
        element={
          <PrivateRoute>
            <DeleteTable />
          </PrivateRoute>
        }
      />

      {/* Table Routes ENDS here */}

      {/* Category Routes STARTS here */}

      <Route
        exact
        path="/categories"
        element={
          <PrivateRoute>
            <Categories />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/categories/create-new-category"
        element={
          <PrivateRoute>
            <AddCategory />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/categories/update-category/:id"
        element={
          <PrivateRoute>
            <AddCategory edit={true} />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/categories/delete-category/:id"
        element={
          <PrivateRoute>
            <DeleteCategory />
          </PrivateRoute>
        }
      />

      {/* Category Routes ENDS here */}

      {/* Terms Routes STARTS here */}
      <Route
        exact
        path="/terms-list"
        element={
          <PrivateRoute>
            <TermsList />
          </PrivateRoute>
        }
      />
      {/* FAQ Routes STARTS here */}
      <Route
        exact
        path="/terms-list/add-new-term"
        element={
          <PrivateRoute>
            <AddTerm />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/terms-list/update-term/:id"
        element={
          <PrivateRoute>
            <AddTerm edit={true} />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/terms-list/delete-term/:id"
        element={
          <PrivateRoute>
            <DeleteTerm />
          </PrivateRoute>
        }
      />
      {/* terms Routes Ends here */}

      {/* FAQ's Routes STARTS here */}

      <Route
        exact
        path="/tags-list"
        element={
          <PrivateRoute>
            <TagsList />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/tags-list/create-new-tag"
        element={
          <PrivateRoute>
            <AddTag />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/tags-list/update-tag/:id"
        element={
          <PrivateRoute>
            <AddTag edit={true} />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/tags-list/delete-tag/:id"
        element={
          <PrivateRoute>
            <DeleteTag />
          </PrivateRoute>
        }
      />

      {/* Tags Routes ENDS here */}

      {/* FAQ Routes STARTS here */}

      <Route
        exact
        path="/faqs-list"
        element={
          <PrivateRoute>
            <FaqList />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/faqs-list/add-new-faq"
        element={
          <PrivateRoute>
            <AddNewFaq />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/faqs-list/update-faq/:id"
        element={
          <PrivateRoute>
            <AddNewFaq edit={true} />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/faqs-list/delete-faq/:id"
        element={
          <PrivateRoute>
            <DeleteFAQ />
          </PrivateRoute>
        }
      />
      {/* SEO Routes STARTS here */}
      {/* 
      <Route
        exact
        path="/seo"
        element={
          <PrivateRoute>
            <SendUrlToGoogle />
          </PrivateRoute>
        }
      /> */}
      {/* SEO Routes ENDS here */}

      {/* Cloudinary Web Story Image Routes Starts here */}
      {/* <Route
        exact
        path="/uploaded-web-story-images"
        element={
          <PrivateRoute>
            <CloudinaryImagesList />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/uploaded-web-story-images/upload-new"
        element={
          <PrivateRoute>
            <UploadImage />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/uploaded-web-story-images/delete-image/:id"
        element={
          <PrivateRoute>
            <DeleteImageFromCloudinary />
          </PrivateRoute>
        }
      /> */}
      {/* Cloudinary Web Story Image Routes Starts here */}
      {/* FAQ's Routes Ends here */}
      <Route
        exact
        path="/logout"
        element={<Logout cancelLink="/dashboard" />}
      />
    </Routes>
  );
};

export default AllRoutes;
