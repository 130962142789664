import React, { useEffect } from "react";
import { MdWarning } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminloginRequest, appLoading } from "../../features/loginSlice";
import { Formik, Field, Form } from "formik";
import validationSchemas from "../../utils/validations/validationSchemas";

const LoginComp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  const errorMessage = useSelector((state) => state.login.hasError);
  const loading = useSelector((state) => state.login.isLoading);

  const onSubmit = (values, { resetForm }) => {
    dispatch(appLoading(true));
    dispatch(adminloginRequest(values));
    resetForm({ values: "" });
  };

  // useEffect(() => {
  //   dispatch(checkServerRequest());
  // }, [dispatch]);

  useEffect(() => {
    isAuthenticated && navigate("dashboard");
  }, [isAuthenticated, navigate]);

  return (
    <section style={{ minHeight: "100vh" }} className="bg-gray-900 pt-12">
      <div className="flex flex-col max-w-md p-6 rounded-md sm:p-10 mx-auto bg-gray-900 border border-transparent lg:border-gray-600 text-gray-100">
        <div className="mb-8 text-center">
          <h1 className="my-3 text-4xl font-bold">Sign in</h1>
          <p className="text-sm text-gray-400">
            Sign in to access your account
          </p>
        </div>
        <Formik
          initialValues={{
            userId: "",
            password: "",
          }}
          validationSchema={validationSchemas.loginSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form className="space-y-12">
              <div className="space-y-4">
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm">
                    User ID
                  </label>
                  <Field
                    className={`${
                      errors.userId && touched.userId
                        ? "border-red-500"
                        : "border-gray-300"
                    } form-control text-base block w-full px-4 py-2  font-normal text-gray-700 bg-white bg-clip-padding border border-solid  rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}
                    name="userId"
                    placeholder="Enter User ID"
                  />
                  {errors.userId && touched.userId ? (
                    <div className="mt-2 text-red-500 flex justify-start items-center ml-1 text-sm">
                      <MdWarning className="mr-1" />
                      {errors.userId}
                    </div>
                  ) : null}
                </div>
                <div>
                  <div className="flex justify-between mb-2">
                    <label htmlFor="password" className="text-sm">
                      Password
                    </label>
                  </div>

                  <Field
                    type="password"
                    className={`${
                      errors.password && touched.password
                        ? "border-red-500"
                        : "border-gray-300"
                    } form-control block w-full px-4 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid  rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}
                    placeholder="Password"
                    name="password"
                  />
                </div>
              </div>
              <div className="space-y-2">
                <div>
                  <button
                    type="submit"
                    className="w-full px-8 py-3 font-semibold rounded-md bg-violet-400 text-gray-100"
                  >
                    {loading && (
                      <svg
                        className="animate-spin  h-5 w-5 mr-3 -ml-2 text-white inline"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    <span>{loading ? "Processing" : "Login"}</span>
                  </button>
                </div>
              </div>
              {errorMessage && (
                <div className="p-3 pl-8 text-red-500">{errorMessage}</div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default LoginComp;
