import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { faqsAPI } from "../api/faqAPI";
import { setMessage, appLoading } from "./appSlice";
// async thunk request to get calls list
export const getAllFaqRequest = createAsyncThunk(
  "faqs/getAllFaqRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await faqsAPI.getAllFaqs();
      dispatch(getFaqsList(list));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
export const createFaqRequest = createAsyncThunk(
  "faqs/createFaqRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await faqsAPI.createNewFaq(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// Google Indexing API Request
export const sendLinksToGoogleRequest = createAsyncThunk(
  "faqs/sendLinksToGoogleRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await faqsAPI.sendUrlToGoogle(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updateFaqRequest = createAsyncThunk(
  "faqs/updateFaqRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await faqsAPI.updateFaq(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const singleFaqRequest = createAsyncThunk(
  "faqs/singleFaqRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      const data = await faqsAPI.singleFaq(input);
      dispatch(setSingleFaq(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteFaqRequest = createAsyncThunk(
  "faqs/deleteFaqRequest",
  async (ID, { dispatch, rejectWithValue }) => {
    try {
      const data = await faqsAPI.deleteFaq(ID);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
const initialState = {
  singleFaq: {},
  faqList: [],
  hasError: "",
};

export const faqSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    getFaqsList: (state, action) => {
      state.faqList = action.payload;
    },
    setSingleFaq: (state, action) => {
      state.singleFaq = action.payload;
    },
    setErrorEmpty: (state) => {
      state.hasError = "";
    },
  },
  extraReducers: {
    [getAllFaqRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [createFaqRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [updateFaqRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [deleteFaqRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [singleFaqRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [sendLinksToGoogleRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
  },
});
export const { getFaqsList, setSingleFaq, setErrorEmpty } = faqSlice.actions;

export default faqSlice.reducer;
