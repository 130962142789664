import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export const tableAPI = {
  getAllTables: async () => {
    return axios.get(`${baseURL}/tables`).then((res) => res.data);
  },
  createNewTable: async (article) => {
    return await axios
      .post(`${baseURL}/tables`, article)
      .then((res) => res.data);
  },
  singleTable: async (id) => {
    return axios.get(`${baseURL}/tables/${id}`).then((res) => res.data);
  },
  updateTable: async (details) => {
    const { id, ...rest } = details;
    return await axios
      .put(`${baseURL}/tables/${id}`, rest)
      .then((res) => res.data);
  },
  deleteTable: async (id) => {
    return await axios
      .delete(`${baseURL}/tables/${id}`)
      .then((res) => res.data);
  },
};
