import axios from "axios";

export const uploadImageAPI = {
  getAllUploadedImages: async () => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/uploaded-web-story-images`)
      .then((res) => res.data);
  },

  deleteImage: async (destryID) => {
    return await axios
      .post(`${process.env.REACT_APP_API_URL}/destroy`, destryID)
      .then((res) => res.data);
  },
};
