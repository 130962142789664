import axios from "axios";

const baseURL = `${process.env.REACT_APP_BASE_URL}/base`;

export const managerAPI = {
  getAllManagers: async () => {
    return axios.get(`${baseURL}/get-all-managers`).then((res) => res.data);
  },
  addNewManager: async (article) => {
    return await axios
      .post(`${baseURL}/add-new-manager`, article)
      .then((res) => res.data);
  },
  singleManager: async (id) => {
    return axios
      .get(`${baseURL}/manager-details/${id}`)
      .then((res) => res.data);
  },
  updateManager: async (details) => {
    const { id, ...rest } = details;
    return await axios
      .put(`${baseURL}/update-manager/${id}`, rest)
      .then((res) => res.data);
  },
  deleteManager: async (id) => {
    return await axios
      .delete(`${baseURL}/delete-manager/${id}`)
      .then((res) => res.data);
  },
};
