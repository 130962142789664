import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MessageModal from "../../shared/loader/MessageModal";
import { setMessage } from "../../features/appSlice";
import NormalDropDown from "../../components/NormalDropDown";
import { setSingleGame, updateChartRequest } from "../../features/gameSlice";
import { gameAPI } from "../../api/gameAPI";
import ToastNotification from "../../shared/ToastNotification";

// function justNumbers(string) {
//   var numsStr = string.replace(/[^0-9]/g, "");
//   return parseInt(numsStr, 10);
// }

const AddNewGame = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.app.message);
  const loading = useSelector((state) => state.app.isLoading);
  // const singleGame = useSelector((state) => state.games.singleGame);
  const [touch1, setTouch1] = useState(false);
  const [touch2, setTouch2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [table, setTable] = useState("");
  const [tableList] = useState([
    "table1",
    "table2",
    "table3",
    "table4",
    "table5",
    "table6",
    "table7",
  ]);
  const [userId, setUserId] = useState("");

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/charts");
  };

  const onCreate = () => {
    let chartTable = table;
    let dataWithId = { chartTable, id };
    dispatch(updateChartRequest(dataWithId));
  };

  useEffect(() => {
    async function getSingleGame(gameid) {
      let single = await gameAPI.singleGame(gameid);
      setTable(single.chartTable);
      setUserId(single.gameName);
      dispatch(setSingleGame(single));
    }
    getSingleGame(id);
  }, [dispatch, id]);

  React.useEffect(() => {
    if (message) {
      setTimeout(() => {
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!message ? (
        <div
          style={{ minHeight: "80vh" }}
          className="mt-4 bg-secoundry shadow-dhruv rounded dark:bg-darkmodeSecoundry"
        >
          <ToastNotification
            message={errorMessage}
            open={errorMessage}
            setOpen={() => setErrorMessage("")}
          />
          <div className=" pb-8">
            <h2 className=" font-semibold px-4 text-lg ml-4 pt-4 text-blue-500 dark:text-darkmodeFots ">
              Update Chart
            </h2>
            <div
              className={`flex justify-start md:flex-row flex-col items-center px-4 pt-4 `}
            >
              <div className="lg:w-2/3 w-full px-8 lg:px-4 mb-5 ">
                <NormalDropDown
                  text="sm" // text-xl text-xs
                  width="w-full" // w-48 , w-1/2 w-full
                  name="select" // name
                  array={[]} // array to iterate
                  py="[6px]" // padding top & bottom
                  touch={touch1} // touch
                  setTouch={setTouch1} // set
                  label={"Game Name"}
                  placeholderText="Select Game"
                  defaultValue="Select Game"
                  value={userId}
                  setValue={setUserId}
                  objProperty="userId"
                />
              </div>
              <div className="lg:w-2/3 w-full  px-8 mb-5 ">
                <NormalDropDown
                  text="sm" // text-xl text-xs
                  width="w-full" // w-48 , w-1/2 w-full
                  name="select" // name
                  array={["hidden", ...tableList]} // array to iterate
                  py="[6px]" // padding top & bottom
                  touch={touch2} // touch
                  setTouch={setTouch2} // set
                  label={"Table"}
                  placeholderText="table"
                  defaultValue="Select Table"
                  value={table === -1 ? "hidden" : table}
                  setValue={setTable}
                  objProperty="chartTable"
                />
              </div>
            </div>

            <div className="px-8 lg:px-3">
              <button
                onClick={() => onCreate()}
                disabled={loading}
                className={` bg-indigo-500 hover:bg-opacity-80 w-full md:w-auto text-sm disabled:bg-gray-400 disabled:text-gray-100 py-2 px-4 rounded text-white mt-4 lg:ml-5 outline-none lg:hover:scale-105 transform`}
              >
                {loading && (
                  <svg
                    className="animate-spin  h-5 w-5 mr-3 -ml-2 text-white inline"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                <span>{loading ? "Processing" : "Submit"}</span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <MessageModal
          open={message}
          setOpen={clearNotificationMessage}
          message={message}
        />
      )}
    </>
  );
};

export default AddNewGame;
