import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { webStoryAPI } from "../api/webStoryAPI";
import { setMessage, appLoading } from "./appSlice";

// async thunk request to get blogs list
export const getAllStoriesRequest = createAsyncThunk(
  "stories/getAllStoriesRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await webStoryAPI.getAllStories();
      dispatch(setStoriesList(list.stories));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to get blogs count
export const getStoriesCount = createAsyncThunk(
  "stories/getStoriesCount",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await webStoryAPI.allStoriesCount();
      dispatch(setStoriesLength(list.length));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to get latest 20 blogs
export const latest24HoursStoriesRequest = createAsyncThunk(
  "stories/latest24HoursStoriesRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await webStoryAPI.getStoriesfromLast24Hours();
      dispatch(setLatestStories(list.stories));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const createStoryRequest = createAsyncThunk(
  "stories/createStoryRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await webStoryAPI.createNewStory(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updateStoryRequest = createAsyncThunk(
  "stories/updateStoryRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await webStoryAPI.updateBlog(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const singleStoryRequest = createAsyncThunk(
  "stories/singleStoryRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      const data = await webStoryAPI.singleStory(input);
      dispatch(setSingleStory(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteStoryRequest = createAsyncThunk(
  "stories/deleteStoryRequest",
  async (ID, { dispatch, rejectWithValue }) => {
    try {
      const data = await webStoryAPI.deleteStory(ID);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
const initialState = {
  singleStory: {},
  stories: [],
  storiesLength: 0,
  latestStories: [],
  previewStory: [],
  storyError: "",
};

export const webStorySlice = createSlice({
  name: "stories",
  initialState,
  reducers: {
    setStoriesList: (state, action) => {
      state.stories = action.payload;
    },
    setPreviewStory: (state, action) => {
      state.previewStory = action.payload;
    },
    setLatestStories: (state, action) => {
      state.latestStories = action.payload;
    },
    setStoriesLength: (state, action) => {
      state.storiesLength = action.payload;
    },

    setSingleStory: (state, action) => {
      state.singleStory = action.payload;
    },
    setErrorEmpty: (state, action) => {
      state.storyError = action.payload;
    },
  },
  extraReducers: {
    [getAllStoriesRequest.rejected]: (state, action) => {
      state.storyError = action.payload;
    },
    [createStoryRequest.rejected]: (state, action) => {
      state.storyError = action.payload;
    },
    [updateStoryRequest.rejected]: (state, action) => {
      state.storyError = action.payload;
    },
    [getStoriesCount.rejected]: (state, action) => {
      state.storyError = action.payload;
    },
    [singleStoryRequest.rejected]: (state, action) => {
      state.storyError = action.payload;
    },
    [latest24HoursStoriesRequest.rejected]: (state, action) => {
      state.storyError = action.payload;
    },
    [deleteStoryRequest.rejected]: (state, action) => {
      state.storyError = action.payload;
    },
  },
});
export const {
  setStoriesList,
  setSingleStory,
  setErrorEmpty,
  setStoriesLength,
  setLatestStories,
  setPreviewStory,
} = webStorySlice.actions;

export default webStorySlice.reducer;
