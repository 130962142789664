import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { uploadImageAPI } from "../api/uploadImageAPI";
import { setMessage, appLoading } from "./appSlice";

// async thunk request to get Images list
export const getAllUploadedImagesRequest = createAsyncThunk(
  "images/getAllUploadedImagesRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await uploadImageAPI.getAllUploadedImages();
      dispatch(listOfImages(list.images));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteImageRequest = createAsyncThunk(
  "images/deleteImageRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await uploadImageAPI.deleteImage(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

const initialState = {
  imagesList: [],
  uploadError: "",
};

export const uploadImageSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    listOfImages: (state, action) => {
      state.imagesList = action.payload;
    },

    setErrorEmpty: (state, action) => {
      state.uploadError = action.payload;
    },
  },
  extraReducers: {
    [getAllUploadedImagesRequest.rejected]: (state, action) => {
      state.uploadError = action.payload;
    },
    [deleteImageRequest.rejected]: (state, action) => {
      state.uploadError = action.payload;
    },
  },
});
export const { listOfImages, setErrorEmpty } = uploadImageSlice.actions;

export default uploadImageSlice.reducer;
