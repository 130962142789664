const returnValue = (value, val, injectString, setValue) => {
  if (value.endsWith(injectString)) {
    val = value.slice(0, -injectString.length);
  } else {
    val = value + injectString;
  }
  setValue(val);
};

export const editorFunctions = (item, value, setValue) => {
  let val = "";

  if (item === "Bold") {
    let injectString = " **BOLDTEXT** ";
    returnValue(value, val, injectString, setValue);
  }
  if (item === "Italic") {
    returnValue(value, val, " *ItalicText*", setValue);
  }
  if (item === "H1") {
    let heading = "###";
    if (value.endsWith(heading)) {
      val = value.slice(0, -heading.length);
    } else {
      val = value + "#";
    }

    setValue(val);
  }
  if (item === "Line") {
    let val = value + "\n***\n";

    setValue(val);
  }
  if (item === "strike") {
    let val = value + "~strikethrough~";

    setValue(val);
  }
  if (item === "Bullet") {
    let val = value + "- ";

    setValue(val);
  }
  if (item === "Number") {
    returnValue(value, val, "\n\n1 - ", setValue);
  }
  if (item === "Blockquote") {
    returnValue(value, val, "\n\n> ", setValue);
  }
  if (item === "Link") {
    let val = value + "[Link TEXT](https://google.com)";
    setValue(val);
  }
  return;
};
