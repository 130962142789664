import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { termsAPI } from "../api/termsAPI";
import { setMessage, appLoading } from "./appSlice";

// async thunk request to get terms list

export const getAllTermsRequest = createAsyncThunk(
  "terms/getAllTermsRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await termsAPI.getAllTerms();
      dispatch(getTermsList(list));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
export const createTermRequest = createAsyncThunk(
  "terms/createTermRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await termsAPI.createNewTerm(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updateTermRequest = createAsyncThunk(
  "terms/updateTermRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await termsAPI.updateTerm(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const singleTermRequest = createAsyncThunk(
  "terms/singleTermRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      const data = await termsAPI.singleTerm(input);
      dispatch(setSingleTerm(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteTermRequest = createAsyncThunk(
  "terms/deleteTermRequest",
  async (ID, { dispatch, rejectWithValue }) => {
    try {
      const data = await termsAPI.deleteTerm(ID);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
const initialState = {
  singleTerm: {},
  termsList: [],
  hasError: "",
};

export const termSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {
    getTermsList: (state, action) => {
      state.termsList = action.payload;
    },
    setSingleTerm: (state, action) => {
      state.singleTerm = action.payload;
    },
    setErrorEmpty: (state) => {
      state.hasError = "";
    },
  },
  extraReducers: {
    [getAllTermsRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [createTermRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [updateTermRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [deleteTermRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [singleTermRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
  },
});
export const { getTermsList, setSingleTerm, setErrorEmpty } = termSlice.actions;

export default termSlice.reducer;
