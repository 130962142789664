import React, { useState } from "react";
import axios from "axios";
import { BsCheck2Circle } from "react-icons/bs";
import { BiBlock } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { setErrorEmpty } from "../../features/UploadedImageSlice";
import { setMessage, appLoading } from "../../features/appSlice";
import ToastNotification from "../../shared/ToastNotification";

const UploadImage = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.app.isLoading);
  const message = useSelector((state) => state.app.message);
  const uploadError = useSelector((state) => state.uploadedImages.uploadError);
  const [file, setFile] = useState({});
  const [imageUploadErrorMessage, setImageErrorMessage] = useState("");

  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return setImageErrorMessage("File not exist.");
    }

    if (
      file.type !== "application/vnd.ms-excel" &&
      file.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return setImageErrorMessage("File format is incorrect.");
    }
    setFile(file);
    const reader = new FileReader();
    setImageErrorMessage("");
    reader.onload = () => {
      if (reader.readyState === 2) {
        // setImagesPreview(reader.result);
      }
    };

    reader.readAsDataURL(file);
  };

  // Submit Blog details synchronously uploading image first and then sending blog data to api
  const onSubmit = () => {
    if (!file.name) {
      return dispatch(setErrorEmpty("Excel File is Required"));
    }
    dispatch(appLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    axios
      .post(`${process.env.REACT_APP_API_URL}/upload-xl-sheet`, formData)
      .then((res) => {
        dispatch(setMessage(res.data.message));
        setFile({});
        dispatch(appLoading(false));
      });
  };

  return (
    <div
      style={{ minHeight: "80vh" }}
      className=" transition-all ease-in-out mt-4 py-3 mb-2 pl-4 dark:bg-darkmodeSecoundry bg-secoundry shadow-dhruv rounded"
    >
      {uploadError && (
        <ToastNotification
          message={uploadError}
          open={uploadError}
          setOpen={() => dispatch(setErrorEmpty(""))}
        />
      )}
      <h2 className="lg:ml-6 pt-1 pb-4 text-xl text-indigo-500 dark:text-darkmodeFots ">
        Upload Excel File
      </h2>
      <div className=" flex items-center lg:ml-6">
        <div className="lg:ml-4">
          <label className=" text-xs text-pink-500 dark:text-darkmodeFots">
            Download Sample Excel Workbook and Fill data in same format
          </label>
          <div className="w-[90%] lg:w-full rounded mt-3 dark:border-gray-500 relative">
            <input
              type="file"
              name="file"
              onChange={handleUpload}
              className=" dark:bg-darkmodeSecoundry outline-none w-full z-30 border dark:text-gray-400 dark:font-semibold"
            />
            <a href="/sample.xlsx" download>
              <button
                className={`mt-3 lg:mt-0  bg-indigo-500 hover:bg-opacity-80 text-sm disabled:bg-gray-400 disabled:text-gray-100 py-2 px-4 rounded text-white  lg:ml-8 outline-none hover:scale-105 transform`}
              >
                Download Sample Excel Sheet
              </button>
            </a>
            {imageUploadErrorMessage && (
              <button className=" bg-red-500 hover:bg-opacity-80 text-sm disabled:bg-gray-400 disabled:text-gray-100 py-2 px-4 rounded text-white  ml-12 outline-none hover:scale-105 transform">
                {imageUploadErrorMessage}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center py-4 px-4">
        <div>
          <button
            onClick={() => onSubmit()}
            disabled={loading}
            className={`  bg-indigo-500 hover:bg-opacity-80 text-sm disabled:bg-gray-400 disabled:text-gray-100 py-2 px-4 rounded text-white  lg:ml-5 outline-none hover:scale-105 transform`}
          >
            {loading && (
              <svg
                className="animate-spin  h-5 w-5 mr-3 -ml-2 text-white inline"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            <span>{loading ? "Processing" : "Bulk Results Upload"}</span>
          </button>
        </div>
      </div>
      {message && (
        <div className="text-xs text-green-600 pl-6 pt-4 flex items-center">
          <BsCheck2Circle className="text-green-600 text-2xl" />
          <p className="text-base ml-2">{message}</p>
        </div>
      )}
      {loading && (
        <div className="text-xs text-green-600 pl-6 pt-4 flex items-center">
          <BiBlock className="text-red-600 text-2xl" />
          <p className="text-base ml-2">
            Bulk Result Upload are in process, It can take upto one minute{" "}
            <br /> Please do not refresh
          </p>
        </div>
      )}
    </div>
  );
};

export default UploadImage;
