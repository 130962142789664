const allTableHeaders = {
  blogTableHeader: [
    {
      name: "Date (Updated)",
      code: "createdAt",
      type: "date",
      customWidth: true,
      formatFunction: "formatDate",
    },
    {
      name: "Title",
      code: "title",
      type: "string",
      customWidth: true,
      width: "w-1/4",
      formatFunction: "smallString",
      stringClip: "55",
      textHighlight: true,
    },
    {
      name: "Category",
      code: "category",
      customWidth: true,
      width: "w-1/5",
      stringClip: "20 ",
      textHighlight: true,
      pillsShow: true,
    },
  ],
  resultTableHeader: [
    {
      name: "Game Name",
      code: "gameName",
      type: "string",
      customWidth: true,
      width: "w-1/4",
      formatFunction: "smallString",
      stringClip: "30",
      textHighlight: true,
    },
    {
      name: "Result",
      code: "result",
      type: "number",
      customWidth: true,
    },
    {
      name: "Result Date",
      code: "resultDate",
      type: "string",
    },
    {
      name: "Updated On",
      code: "updatedAt",
      type: "date",
      formatFunction: "formatDate",
    },
  ],
  contentTableHeader: [
    {
      name: "Page Title",
      code: "pageTitle",
      type: "string",
      customWidth: true,
      width: "w-3/12",
      formatFunction: "smallString",
      stringClip: "40",
      textHighlight: true,
    },
    {
      name: "Page Content",
      code: "pageContent",
      type: "string",
      customWidth: true,
      width: "w-2/5",
      formatFunction: "smallString",
      stringClip: "40",
    },
    {
      name: "Status",
      code: "status",
      type: "string",
    },
    {
      name: "Date",
      code: "createdAt",
      type: "date",
      formatFunction: "formatDate",
    },
  ],
  sliderTextTableHeader: [
    {
      name: "Slider Title",
      code: "title",
      type: "string",
      customWidth: true,
      width: "w-1/5",
      formatFunction: "smallString",
      stringClip: "30",
      textHighlight: true,
    },
    {
      name: "Text",
      code: "text",
      type: "string",
      customWidth: true,
      width: "w-1/3",
      formatFunction: "smallString",
      stringClip: "40",
    },
    {
      name: "Status",
      code: "status",
      type: "string",
    },
    {
      name: "Date",
      code: "createdAt",
      type: "date",
      formatFunction: "formatDate",
    },
  ],
  userRolesTableHeader: [
    {
      name: "Role",
      code: "role",
      type: "string",
      customWidth: true,
      width: "w-1/2",
      formatFunction: "smallString",
      stringClip: "30",
      textHighlight: true,
    },
    {
      name: "Date",
      code: "createdAt",
      type: "date",
      formatFunction: "formatDate",
    },
  ],
  usersTableHeader: [
    {
      name: "Full Name",
      code: "fullName",
      type: "string",
      customWidth: false,
      formatFunction: "smallString",
      stringClip: "20",
      textHighlight: true,
    },
    {
      name: "Password",
      code: "password",
      type: "string",
    },
    {
      name: "Mobile",
      code: "phone",
      type: "string",
    },
    {
      name: "User Role",
      code: "userRole",
      type: "string",
    },
    {
      name: "OTP",
      code: "otp",
      type: "string",
    },

    {
      name: "Status",
      code: "status",
      type: "string",
    },
    {
      name: "Created On",
      code: "createdAt",
      type: "date",
      formatFunction: "formatDate",
    },
  ],
  termsTableHeader: [
    {
      name: "Title",
      code: "title",
      type: "string",
      customWidth: true,
      width: "w-1/4",
      formatFunction: "smallString",
      stringClip: "30",
      textHighlight: true,
    },
    {
      name: "Term",
      code: "term",
      type: "string",
      customWidth: true,
      width: "w-1/3",
      formatFunction: "smallString",
      stringClip: "40",
    },

    {
      name: "Date",
      code: "createdAt",
      type: "date",
      formatFunction: "formatDate",
    },
  ],
  notificationsTableHeader: [
    {
      name: "Title",
      code: "title",
      type: "string",
      customWidth: true,
      width: "w-1/5",
      formatFunction: "smallString",
      stringClip: "30",
      textHighlight: true,
    },
    {
      name: "Content",
      code: "content",
      type: "string",
      customWidth: true,
      width: "w-1/3",
      formatFunction: "smallString",
      stringClip: "110",
    },
    {
      name: "Status",
      code: "status",
      type: "string",
    },
    {
      name: "Date",
      code: "createdAt",
      type: "date",
      formatFunction: "formatDate",
    },
  ],
  adsTableHeader: [
    {
      name: "Name",
      code: "ownerName",
      type: "string",
      formatFunction: "smallString",
      stringClip: "25",
      textHighlight: true,
    },
    {
      name: "Ad Title",
      code: "adsTitle",
      type: "string",
      formatFunction: "smallString",
      stringClip: "15",
    },
    {
      name: "Ad Content",
      code: "adContent",
      type: "string",
      formatFunction: "smallString",
      stringClip: "15",
    },
    {
      name: "WhatsApp",
      code: "whatsApp",
      type: "string",
    },
    {
      name: "Valid To",
      code: "validToDate",
      type: "date",
      formatFunction: "formatDate",
    },
  ],
  managerTableHeader: [
    {
      name: "Name",
      code: "name",
      type: "string",
      formatFunction: "smallString",
      stringClip: "25",
      textHighlight: true,
    },
    {
      name: "User Name",
      code: "userId",
      type: "string",
      formatFunction: "smallString",
      stringClip: "15",
    },
    {
      name: "Password",
      code: "password",
      type: "string",
    },
    {
      name: "Mobile",
      code: "mobile",
      type: "number",
    },
    {
      name: "Joined on",
      code: "createdAt",
      type: "date",
      formatFunction: "formatDate",
    },
    {
      name: "Updated on",
      code: "updatedAt",
      type: "date",
      formatFunction: "formatDate",
    },
  ],
  gameTableHeader: [
    {
      name: "Game Name",
      code: "gameName",
      type: "string",
      formatFunction: "smallString",
      stringClip: "15",
      textHighlight: true,
    },
    {
      name: "User Name",
      code: "userId",
      type: "string",
      formatFunction: "smallString",
      stringClip: "15",
    },

    {
      name: "Result Time",
      code: "resultTime",
      type: "string",
    },
    {
      name: "Wait Time",
      code: "waitTime",
      type: "string",
    },
    {
      name: "Primary",
      code: "primary",
    },
    {
      name: "Valid to",
      code: "toDate",
    },
  ],
  chartTableHeader: [
    {
      name: "Game Name",
      code: "gameName",
      type: "string",
      formatFunction: "smallString",
      stringClip: "15",
      textHighlight: true,
    },
    {
      name: "Table",
      code: "chartTable",
      type: "string",
    },
  ],
  faqTableHeader: [
    {
      name: "Question",
      code: "question",
      type: "string",
      customWidth: true,
      width: "w-1/3",
      formatFunction: "smallString",
      stringClip: "50",
      textHighlight: true,
    },
    {
      name: "Answer",
      code: "answer",
      type: "string",
      customWidth: true,
      width: "w-1/3",
      formatFunction: "smallString",
      stringClip: "50",
    },
    {
      name: "Page",
      code: "category",
      type: "string",
    },
    {
      name: "Date",
      code: "createdAt",
      type: "date",
      formatFunction: "formatDate",
    },
  ],
};

export default allTableHeaders;
