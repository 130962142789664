import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { gameAPI } from "../api/gameAPI";
import { resultAPI } from "../api/resultAPI";
import { setMessage, appLoading } from "./appSlice";
// async thunk request to get calls list
export const getAllGamesRequest = createAsyncThunk(
  "games/getAllGamesRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await gameAPI.getAllGames();
      dispatch(getGamesList(list));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
export const addGameRequest = createAsyncThunk(
  "games/addGameRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await gameAPI.addNewGame(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
      await resultAPI.revalidatePage();
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updateGameRequest = createAsyncThunk(
  "games/updateGameRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await gameAPI.updateGame(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
      await resultAPI.revalidatePage();
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updateChartRequest = createAsyncThunk(
  "games/updateChartRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await gameAPI.updateChart(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
      await resultAPI.revalidatePage();
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const singleGameRequest = createAsyncThunk(
  "games/singleGameRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      const data = await gameAPI.singleGame(input);
      dispatch(setSingleGame(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteGameRequest = createAsyncThunk(
  "games/deleteGameRequest",
  async (ID, { dispatch, rejectWithValue }) => {
    try {
      const data = await gameAPI.deleteGame(ID);
      dispatch(setMessage(data.message));
      await resultAPI.revalidatePage();
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
const initialState = {
  singleGame: {},
  gamesList: [],
  hasError: "",
};

export const gameSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    getGamesList: (state, action) => {
      state.gamesList = action.payload;
    },
    setSingleGame: (state, action) => {
      state.singleGame = action.payload;
    },
    setErrorEmpty: (state) => {
      state.hasError = "";
    },
  },
  extraReducers: {
    [getAllGamesRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [addGameRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [updateGameRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [deleteGameRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [singleGameRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [updateChartRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
  },
});
export const { getGamesList, setSingleGame, setErrorEmpty } = gameSlice.actions;

export default gameSlice.reducer;
