import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import validationSchemas from "../../utils/validations/validationSchemas";
import CustomBtn from "../../components/CustomBtn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MessageModal from "../../shared/loader/MessageModal";
import { setMessage } from "../../features/appSlice";
import CustomInput from "../../components/CustomInput";
import {
  createTermRequest,
  singleTermRequest,
  updateTermRequest,
} from "../../features/termSlice";

const AddNewFaq = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.app.message);
  const loading = useSelector((state) => state.app.isLoading);
  const submitError = useSelector((state) => state.terms.hasError);
  const singleTerm = useSelector((state) => state.terms.singleTerm);

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/terms-list");
  };

  const onCreate = (values) => {
    if (props.edit) {
      let dataWithId = { ...values, id: singleTerm._id };
      dispatch(updateTermRequest(dataWithId));
    } else {
      dispatch(createTermRequest(values));
    }
  };

  useEffect(() => {
    if (props.edit) {
      dispatch(singleTermRequest(id));
    }
  }, [dispatch, props.edit, id]);

  React.useEffect(() => {
    if (message) {
      setTimeout(() => {
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!message ? (
        <div
          style={{ minHeight: "80vh" }}
          className="mt-4   bg-secoundry shadow-dhruv rounded dark:bg-darkmodeSecoundry "
        >
          <div className=" pb-8">
            <h2 className=" font-semibold px-4 text-lg lg:ml-4 pt-4 text-blue-500 dark:text-darkmodeFots ">
              {props.edit ? "Update Content" : "Create Content"}
            </h2>
            <Formik
              enableReinitialize
              initialValues={{
                title: (props.edit && singleTerm.title) || "",
                term: (props.edit && singleTerm.term) || "",
              }}
              validationSchema={validationSchemas.termSchema}
              onSubmit={onCreate}
            >
              {({ errors, touched }) => (
                <Form autoComplete="off">
                  <div className={`flex justify-start items-center pt-4 `}>
                    <div className=" lg:w-2/3  w-full lg:px-4">
                      <div className={`px-4 w-full`}>
                        <CustomInput
                          errors={errors.title}
                          touched={touched.title}
                          title="Enter Title"
                          name="title"
                          placeholder="Enter Title"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="lg:px-8 px-4 lg:w-2/3 w-full">
                    <div className="col-span-2">
                      <div className=" text-gray-600 font-medium text-sm mb-1 dark:text-gray-300 ">
                        Content
                      </div>
                      <div>
                        <div
                          className={`${
                            errors.term && touched.term ? "mb-0" : "mb-4"
                          }`}
                        >
                          <Field
                            placeholder="Enter Content..."
                            as="textarea"
                            name="term"
                            className={`${
                              errors.term && touched.term
                                ? "border-red-500"
                                : "border-gray-300"
                            }   outline-none min-h-[10rem]  border rounded py-2 font-normal transition-all duration-300 focus:border-indigo-500 dark:border-gray-500 px-3 w-full text-sm text-gray-700 caret-indigo-600 dark:bg-darkmodeSecoundry`}
                          />
                        </div>
                        {errors.term && touched.term ? (
                          <div className="pt-2 text-red-500 text-xs">
                            {errors.term}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="px-3 py-4">
                    <CustomBtn
                      type="submit"
                      text={props.edit ? "Update Content" : "Create Content"}
                      loading={loading}
                    />
                  </div>
                  {submitError && (
                    <div className="p-3 pl-8 text-red-500">{submitError}</div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <MessageModal
          open={message}
          setOpen={clearNotificationMessage}
          message={message}
        />
      )}
    </>
  );
};

export default AddNewFaq;
