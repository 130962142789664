import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { setDropDown } from "../../features/appSlice";

const CategoriesDropDown = (props) => {
  const dispatch = useDispatch();
  const showDropDown = useSelector((state) => state.app.showDropDown);
  const onGenerate = (val) => {
    props.setSelectedCategory(val);
    dispatch(setDropDown(false));
  };
  const showDropDownFunction = () => {
    !showDropDown && dispatch(setDropDown(true));
  };
  return (
    <div className="mb-4">
      <h3 className="mb-1 text-sm text-gray-500 ">{props.title}</h3>
      <div
        onClick={showDropDownFunction}
        className={` border ${
          showDropDown ? "border-indigo-500" : "border-Textgray"
        } rounded flex relative justify-between items-center h-9 pl-4 select-none`}
      >
        <div className="text-sm text-gray-400 ">
          {props.category ? props.category : "Select Category"}
        </div>

        {showDropDown ? (
          <IoIosArrowUp className="mr-2 text-indigo-500 cursor-pointer " />
        ) : (
          <IoIosArrowDown className="mr-2 cursor-pointer text-iconColor" />
        )}

        {showDropDown && (
          <div className=" absolute z-40 bg-white shadow-dhruv w-full top-10 left-0 max-h-[12rem] overflow-y-scroll">
            {props.ArrayList &&
              props.ArrayList.length > 0 &&
              props.ArrayList.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      onGenerate(item.slug);
                    }}
                    style={{ color: item.gameName }}
                    className="shadow-xl hover:bg-black text-xs py-1.5 pl-4   hover:text-white "
                  >
                    {item.gameName}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoriesDropDown;
