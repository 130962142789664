import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export const categoryAPI = {
  getAllCategories: async () => {
    return axios.get(`${baseURL}/category`).then((res) => res.data);
  },
  createNewCategory: async (article) => {
    return await axios
      .post(`${baseURL}/category`, article)
      .then((res) => res.data);
  },
  singleCategory: async (id) => {
    return axios.get(`${baseURL}/category/${id}`).then((res) => res.data);
  },
  updateCategory: async (details) => {
    const { id, ...rest } = details;
    return await axios
      .put(`${baseURL}/category/${id}`, rest)
      .then((res) => res.data);
  },
  deleteCategory: async (id) => {
    return await axios
      .delete(`${baseURL}/category/${id}`)
      .then((res) => res.data);
  },
};
