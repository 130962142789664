import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export const resultAPI = {
  getAllResults: async () => {
    return axios.get(`${baseURL}/results-all`).then((res) => res.data);
  },
  latestResults: async () => {
    return axios
      .get(`${baseURL}/results-from-last-24-hours`)
      .then((res) => res.data);
  },
  publishNewResult: async (article) => {
    return await axios
      .post(`${baseURL}/results`, article)
      .then((res) => res.data);
  },
  singleResult: async (id) => {
    return await axios.get(`${baseURL}/results/${id}`).then((res) => res.data);
  },
  revalidatePage: async (id) => {
    return await axios
      .get(
        `${process.env.REACT_APP_CLIENT_URL}/api/indexRevalidate?secret=${process.env.REACT_APP_REVALIDATE_SECRET}`
      )
      .then((res) => res.data);
  },
  updateResult: async (details) => {
    const { id, ...rest } = details;
    return await axios
      .put(`${baseURL}/results/${id}`, rest)
      .then((res) => res.data);
  },
  deleteResult: async (id) => {
    return await axios
      .delete(`${baseURL}/results/${id}`)
      .then((res) => res.data);
  },
};
