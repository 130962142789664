import axios from "axios";
//
const baseURL = `${process.env.REACT_APP_BASE_URL}/base`;

export const adminAPI = {
  loginRequest: async (adminData) => {
    return axios.post(`${baseURL}/login`, adminData).then((res) => res.data);
  },
  checkserver: async () => {
    return axios
      .get(`${process.env.REACT_APP_BASE_URL}/check`)
      .then((res) => res.data);
  },
  logoutCall: async () => {
    return axios.get(`${baseURL}/logout`).then((res) => res.data);
  },
  getuserRequest: async () => {
    return axios.get(`${baseURL}/getuser`).then((res) => res.data);
  },
};
