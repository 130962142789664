import React, { useState, useRef } from "react";
import axios from "axios";
import { BiLinkAlt, BiImage, BiBorderBottom } from "react-icons/bi";
import {
  MdOutlineFormatListNumbered,
  MdOutlineFormatListBulleted,
  MdStrikethroughS,
} from "react-icons/md";
import { FaQuoteLeft, FaCode } from "react-icons/fa";
import { editorFunctions } from "./editorFunctions";
import ToastNotification from "../../shared/ToastNotification";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { markdownStyleObj } from "./markdownElementStyling";

const Editor = (props) => {
  const [markdown, setMarkdown] = useState(false);
  const [errorMessage, setImageErrorMessage] = useState("");
  const ref = useRef(null);
  const handleBlogImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return setImageErrorMessage("File not exist.");
    }
    if (file.size > 1024 * 1024) {
      return setImageErrorMessage("Size too large!");
    }
    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      return setImageErrorMessage("File format is incorrect.");
    }
    if (file.name) {
      let processingText = props.textValue + "![Description](processing.....)";
      props.editText(processingText);
      let formData = new FormData();
      formData.append("file", file);
      let { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/upload`,
        formData
      );
      let finalText = props.textValue + `![Description](${data.url})`;
      return props.editText(finalText);
    }
  };
  return (
    <div className=" border-Textgray border rounded dark:border-gray-500">
      {errorMessage && (
        <ToastNotification
          message={errorMessage}
          open={errorMessage}
          setOpen={() => setImageErrorMessage("")}
        />
      )}
      <div className=" border-b border-b-Textgray flex items-center px-3 py-2 dark:border-gray-500">
        <div>
          <button
            type="button"
            onClick={() => {
              setMarkdown((prev) => !prev);
            }}
            className=" p-1.5 text-xs border mr-4 border-gray-300 text-blue-600 rounded hover:bg-gray-100 px-2"
          >
            {markdown ? "Edit Mode" : "Check Preview"}
          </button>
        </div>
        {!markdown && (
          <div className="flex justify-evenly items-center">
            <button
              type="button"
              onClick={() => {
                editorFunctions("Bold", props.textValue, props.editText);
              }}
              className="select-none  dark:text-darkmodeFots dark:hover:bg-gray-200 font-semibold text-gray-500 text-sm mx-1 p-1 px-2 hover:rounded-full  hover:bg-gray-200 "
            >
              B
            </button>
            <button
              type="button"
              onClick={() => {
                editorFunctions("Italic", props.textValue, props.editText);
              }}
              className="  dark:text-darkmodeFots dark:hover:bg-gray-200 font-semibold text-gray-500 text-sm p-1 px-3 hover:rounded-full  hover:bg-gray-200"
            >
              I
            </button>
            <div
              onClick={() => {
                editorFunctions("Link", props.textValue, props.editText);
              }}
              className="hover:bg-gray-200 group rounded-full p-1 mx-2"
            >
              <BiLinkAlt className="  dark:text-darkmodeFots dark:hover:bg-blue-900 text-gray-600  group-hover:text-blue-500 text-xl" />
            </div>
            <div
              onClick={() => {
                editorFunctions("Bullet", props.textValue, props.editText);
              }}
              className="hover:bg-gray-200 group rounded-full p-1 "
            >
              <MdOutlineFormatListBulleted className=" dark:text-darkmodeFots dark:hover:bg-blue-900 text-gray-600  group-hover:text-blue-500 text-xl" />
            </div>
            <div
              onClick={() => {
                editorFunctions("Number", props.textValue, props.editText);
              }}
              className="hover:bg-gray-200 group rounded-full p-1 mx-3"
            >
              <MdOutlineFormatListNumbered className="  dark:text-darkmodeFots dark:hover:bg-blue-900 text-gray-600  group-hover:text-blue-500 text-xl " />
            </div>

            <button
              type="button"
              onClick={() => {
                editorFunctions("H1", props.textValue, props.editText);
              }}
              className=" select-none  dark:text-darkmodeFots dark:hover:bg-gray-200 font-semibold text-gray-500 text-sm mx-1 p-1 px-2 hover:rounded-full  hover:bg-gray-200"
            >
              H
            </button>

            <div
              onClick={() => {
                editorFunctions("Blockquote", props.textValue, props.editText);
              }}
              title="BlockQuote"
              className="hover:bg-gray-200 group rounded-full p-1.5 mx-2"
            >
              <FaQuoteLeft className=" dark:text-darkmodeFots text-gray-500 group-hover:text-blue-500 dark:hover:bg-blue-900 text-sm" />
            </div>
            <div
              onClick={() => {
                editorFunctions("Line", props.textValue, props.editText);
              }}
              title="Bottom Border"
              className="hover:bg-gray-200 group rounded-full p-1.5 mx-2"
            >
              <BiBorderBottom className=" dark:text-darkmodeFots text-gray-500 group-hover:text-blue-500 dark:hover:bg-blue-900 text-sm" />
            </div>
            <div
              onClick={() => {
                editorFunctions("strike", props.textValue, props.editText);
              }}
              title="Strike Text"
              className="hover:bg-gray-200 group rounded-full p-1 mr-2"
            >
              <MdStrikethroughS className=" dark:text-darkmodeFots text-gray-600 group-hover:text-blue-500 dark:hover:bg-blue-900 text-lg" />
            </div>
            <div
              onClick={() => {
                editorFunctions("Image", props.textValue, props.editText);
              }}
              title="single line code"
              className="hover:bg-gray-200 group rounded-full p-1.5 mr-1"
            >
              <FaCode className=" dark:text-darkmodeFots text-gray-500 group-hover:text-blue-500 dark:hover:bg-blue-900 text-lg" />
            </div>
            <div
              title="Upload image upto 1MB (PNG/JPG)"
              className="hover:bg-gray-200 group max-w-[2rem] rounded-full relative mr-4"
            >
              <input
                type="file"
                name="file"
                className="opacity-0 w-10/12 z-20 relative "
                onChange={handleBlogImageUpload}
              />
              <button className="absolute w-full top-1 left-1.5 z-0">
                <BiImage className=" dark:text-darkmodeFots text-gray-600 group-hover:text-blue-500 dark:hover:bg-blue-900 text-xl" />
              </button>
            </div>
          </div>
        )}
      </div>
      {markdown ? (
        <div className="min-h-[16rem] p-2 py-3 px-8 text-gray-700 list-disc leading-8">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={markdownStyleObj}
            children={props.textValue}
          />
        </div>
      ) : (
        <div>
          <textarea
            id="editor"
            autoFocus
            ref={ref}
            className=" w-full min-h-[16rem] py-3 caret-red-500 outline-none pl-4 text-sm text-gray-500 dark:bg-darkmodeSecoundry"
            placeholder="Insert Text Here...."
            value={props.textValue}
            onChange={(e) => {
              props.editText(e.target.value);
            }}
          ></textarea>
        </div>
      )}
    </div>
  );
};

export default Editor;
