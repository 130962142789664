import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export const termsAPI = {
  getAllTerms: async () => {
    return axios.get(`${baseURL}/legal-documents`).then((res) => res.data);
  },
  createNewTerm: async (article) => {
    return await axios
      .post(`${baseURL}/legal-documents`, article)
      .then((res) => res.data);
  },
  singleTerm: async (id) => {
    return axios
      .get(`${baseURL}/legal-documents/${id}`)
      .then((res) => res.data);
  },
  updateTerm: async (details) => {
    const { id, ...rest } = details;
    return await axios
      .put(`${baseURL}/legal-documents/${id}`, rest)
      .then((res) => res.data);
  },
  deleteTerm: async (id) => {
    return await axios
      .delete(`${baseURL}/legal-documents/${id}`)
      .then((res) => res.data);
  },
};
