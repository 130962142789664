import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { resultAPI } from "../api/resultAPI";
import { setMessage, appLoading } from "./appSlice";
// async thunk request to get calls list
export const getAllResultsRequest = createAsyncThunk(
  "results/getAllResultsRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await resultAPI.getAllResults();
      dispatch(getResultsList(list.results));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to get latest results
export const getLatestResultRequest = createAsyncThunk(
  "results/getLatestResultRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await resultAPI.latestResults();
      dispatch(setLatestResults(list.results));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const publishResultRequest = createAsyncThunk(
  "results/publishResultRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await resultAPI.publishNewResult(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
      await resultAPI.revalidatePage();
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updateResultRequest = createAsyncThunk(
  "results/updateResultRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await resultAPI.updateResult(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
      await resultAPI.revalidatePage();
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const singleResultRequest = createAsyncThunk(
  "results/singleResultRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      const data = await resultAPI.singleResult(input);
      dispatch(setSingleResult(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteResultRequest = createAsyncThunk(
  "results/deleteResultRequest",
  async (ID, { dispatch, rejectWithValue }) => {
    try {
      const data = await resultAPI.deleteResult(ID);
      dispatch(setMessage(data.message));
      await resultAPI.revalidatePage();
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

const initialState = {
  singleResult: {},
  resultsList: [],
  latestResults: [],
  hasError: "",
};

export const resultSlice = createSlice({
  name: "results",
  initialState,
  reducers: {
    getResultsList: (state, action) => {
      state.resultsList = action.payload;
    },
    setSingleResult: (state, action) => {
      state.singleResult = action.payload;
    },
    setLatestResults: (state, action) => {
      state.latestResults = action.payload;
    },
    setErrorEmpty: (state) => {
      state.hasError = "";
    },
  },
  extraReducers: {
    [getAllResultsRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [publishResultRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [updateResultRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [deleteResultRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [singleResultRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [getLatestResultRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
  },
});
export const {
  getResultsList,
  setSingleResult,
  setErrorEmpty,
  setLatestResults,
} = resultSlice.actions;

export default resultSlice.reducer;
