import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { blogsAPI } from "../api/blogsAPI";
import { setMessage, appLoading } from "./appSlice";

// async thunk request to get blogs list
export const getAllBlogRequest = createAsyncThunk(
  "blogs/getAllBlogRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await blogsAPI.getAllBlogs();
      dispatch(setBlogList(list.data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to get blogs count
export const getBlogsCount = createAsyncThunk(
  "blogs/getBlogsCount",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await blogsAPI.allBlogsCount();
      dispatch(setBlogList(list.length));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to get latest 20 blogs
export const latest24HoursBlogRequest = createAsyncThunk(
  "blogs/latest24HoursBlogRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await blogsAPI.getBlogsfromLast24Hours();
      dispatch(setLatestBlogs(list.blogs));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const createBlogRequest = createAsyncThunk(
  "blogs/createBlogRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await blogsAPI.createNewBlog(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updateBlogRequest = createAsyncThunk(
  "blogs/updateBlogRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await blogsAPI.updateBlog(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const singleBlogRequest = createAsyncThunk(
  "blogs/singleBlogRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      const data = await blogsAPI.singleBlog(input);
      dispatch(setSingleBlog(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteBlogRequest = createAsyncThunk(
  "blogs/deleteBlogRequest",
  async (ID, { dispatch, rejectWithValue }) => {
    try {
      const data = await blogsAPI.deleteBlog(ID);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
const initialState = {
  singleBlog: {},
  blogList: [],

  blogsLength: 0,
  latestBlogs: [],
  blogError: "",
};

export const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    setBlogList: (state, action) => {
      state.blogList = action.payload;
    },
    setLatestBlogs: (state, action) => {
      state.latestBlogs = action.payload;
    },
    setBlogsLength: (state, action) => {
      state.blogsLength = action.payload;
    },

    setSingleBlog: (state, action) => {
      state.singleBlog = action.payload;
    },
    setErrorEmpty: (state, action) => {
      state.blogError = action.payload;
    },
  },
  extraReducers: {
    [getAllBlogRequest.rejected]: (state, action) => {
      state.blogError = action.payload;
    },
    [createBlogRequest.rejected]: (state, action) => {
      state.blogError = action.payload;
    },
    [updateBlogRequest.rejected]: (state, action) => {
      state.blogError = action.payload;
    },
    [getBlogsCount.rejected]: (state, action) => {
      state.blogError = action.payload;
    },
    [singleBlogRequest.rejected]: (state, action) => {
      state.blogError = action.payload;
    },
    [latest24HoursBlogRequest.rejected]: (state, action) => {
      state.blogError = action.payload;
    },
    [deleteBlogRequest.rejected]: (state, action) => {
      state.blogError = action.payload;
    },
  },
});
export const {
  setBlogList,
  setSingleBlog,
  setErrorEmpty,
  setBlogsLength,
  setLatestBlogs,
} = blogSlice.actions;

export default blogSlice.reducer;
