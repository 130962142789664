import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import validationSchemas from "../../utils/validations/validationSchemas";
import CustomBtn from "../../components/CustomBtn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MessageModal from "../../shared/loader/MessageModal";
import { setMessage } from "../../features/appSlice";
import { getAllGamesRequest } from "../../features/gameSlice";
import CustomInput from "../../components/CustomInput";
import {
  createFaqRequest,
  singleFaqRequest,
  updateFaqRequest,
} from "../../features/faqSlice";
import CategoriesDropDown from "../../components/editor/CategoriesDropDown";

const AddNewFaq = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.app.message);
  const loading = useSelector((state) => state.app.isLoading);
  const submitError = useSelector((state) => state.faqs.hasError);
  const singleFaq = useSelector((state) => state.faqs.singleFaq);
  const categoryArray = useSelector((state) => state.games.gamesList);
  const [category, setSelectedCategory] = useState("");

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/faqs-list");
  };

  const onCreate = (values) => {
    if (!category) return;
    if (props.edit) {
      let dataWithId = { ...values, id: singleFaq._id, category };
      dispatch(updateFaqRequest(dataWithId));
    } else {
      dispatch(createFaqRequest({ ...values, category }));
    }
  };

  useEffect(() => {
    if (props.edit) {
      dispatch(singleFaqRequest(id));
    }

    dispatch(getAllGamesRequest());
  }, [dispatch, props.edit, id]);

  React.useEffect(() => {
    if (message) {
      setTimeout(() => {
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!message ? (
        <div
          style={{ minHeight: "80vh" }}
          className="mt-4 rounded bg-secoundry shadow-dhruv dark:bg-darkmodeSecoundry "
        >
          <div className="pb-8 ">
            <h2 className="px-4 pt-4 ml-4 text-lg font-semibold text-blue-500 dark:text-darkmodeFots">
              {props.edit ? "Update FAQ" : "Create FAQ"}
            </h2>
            <Formik
              enableReinitialize
              initialValues={{
                question: (props.edit && singleFaq.question) || "",
                answer: (props.edit && singleFaq.answer) || "",
              }}
              validationSchema={validationSchemas.faqSchema}
              onSubmit={onCreate}
            >
              {({ errors, touched }) => (
                <Form autoComplete="off">
                  <div className={`flex justify-start items-center pt-4 `}>
                    <div className="w-full lg:px-4">
                      <div className={`px-4 w-full`}>
                        <CustomInput
                          errors={errors.question}
                          touched={touched.question}
                          title="Enter Question"
                          name="question"
                          placeholder="Enter Question"
                        />
                      </div>
                    </div>
                    <div
                      className={`${
                        errors.title && touched.title && "mb-7"
                      } mx-5 w-1/2 `}
                    >
                      <CategoriesDropDown
                        title="Category"
                        ArrayList={[
                          { gameName: "index", slug: "index" },
                          { gameName: "chart", slug: "chart" },
                          ...categoryArray,
                        ]}
                        setSelectedCategory={setSelectedCategory}
                        category={category}
                        edit={props.edit}
                      />
                    </div>
                  </div>
                  <div className="w-full px-4 lg:px-8 lg:w-2/3">
                    <div className="col-span-2">
                      <div className="mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                        Answer
                      </div>
                      <div>
                        <div
                          className={`${
                            errors.answer && touched.answer ? "mb-0" : "mb-4"
                          }`}
                        >
                          <Field
                            placeholder="Enter Answer"
                            as="textarea"
                            name="answer"
                            className={`${
                              errors.answer && touched.answer
                                ? "border-red-500"
                                : "border-gray-300"
                            }   outline-none min-h-[10rem]  border rounded py-2 font-normal transition-all duration-300 focus:border-indigo-500 dark:border-gray-500 px-3 w-full text-sm text-gray-700 caret-indigo-600 dark:bg-darkmodeSecoundry`}
                          />
                        </div>
                        {errors.answer && touched.answer ? (
                          <div className="pt-2 text-xs text-red-500">
                            {errors.answer}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="px-3 py-4">
                    <CustomBtn
                      type="submit"
                      text={props.edit ? "Update FAQ" : "Create FAQ"}
                      loading={loading}
                    />
                  </div>
                  {submitError && (
                    <div className="p-3 pl-8 text-red-500">{submitError}</div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <MessageModal
          open={message}
          setOpen={clearNotificationMessage}
          message={message}
        />
      )}
    </>
  );
};

export default AddNewFaq;
