import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export const adsAPI = {
  getAllAds: async () => {
    return axios.get(`${baseURL}/ads-all`).then((res) => res.data);
  },
  createNewAd: async (article) => {
    return await axios.post(`${baseURL}/ads`, article).then((res) => res.data);
  },
  singleAd: async (id) => {
    return await axios.get(`${baseURL}/ads/${id}`).then((res) => res.data);
  },
  updateAd: async (details) => {
    const { id, ...rest } = details;
    return await axios
      .put(`${baseURL}/ads/${id}`, rest)
      .then((res) => res.data);
  },
  deleteAd: async (id) => {
    return await axios.delete(`${baseURL}/ads/${id}`).then((res) => res.data);
  },
};
