import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";

const PillPart = (props) => {
  const [showEdit, setEdit] = useState(false);
  return (
    <div
      onMouseEnter={() => {
        !showEdit && setEdit(true);
      }}
      onMouseLeave={() => {
        showEdit && setEdit(false);
      }}
      key={props.index}
      className="border relative dark:text-gray-50  flex items-center border-indigo-200 shadow-transparent bg-transparent transition-background hover:cursor-pointer duration-300 ease-in-out hover:bg-indigo-400 py-4 px-0.5 group hover:border-indigo-400"
    >
      <h4 className="text-gray-700 group-hover:text-indigo-300  dark:text-gray-50 text-xs pl-3 ">
        {props.property}
      </h4>
      {showEdit && (
        <div className="flex items-center absolute left-1/3">
          <Link to={`${props.updateString}/${props.id}`}>
            <p className="p-2 bg-white hover:bg-white text-center rounded-full">
              <FaEdit
                title="Update"
                className="text-sm    hover:text-green-500 dark:text-darkmodeFots"
              />
            </p>
          </Link>
          <p className="mx-4"></p>
          <Link to={`${props.deleteString}/${props.id}`}>
            <p className="p-2 bg-white hover:bg-white text-center rounded-full">
              <BsTrash
                title="Delete"
                className="text-sm    hover:text-red-500 dark:text-darkmodeFots"
              />
            </p>{" "}
          </Link>
        </div>
      )}
    </div>
  );
};

export default PillPart;
