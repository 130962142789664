import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { categoryAPI } from "../api/categoryAPI";
import { setMessage, appLoading } from "./appSlice";
// async thunk request to get calls list
export const getAllCategoriesRequest = createAsyncThunk(
  "category/getAllCategoriesRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await categoryAPI.getAllCategories();
      dispatch(getCategoriesList(list));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
export const createCategoryRequest = createAsyncThunk(
  "category/createCategoryRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await categoryAPI.createNewCategory(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updateCategoryRequest = createAsyncThunk(
  "category/updateCategoryRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await categoryAPI.updateCategory(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const singleCategoryRequest = createAsyncThunk(
  "category/singleCategoryRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      const data = await categoryAPI.singleCategory(input);
      dispatch(setSingleCategory(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteCategoryRequest = createAsyncThunk(
  "category/deleteCategoryRequest",
  async (ID, { dispatch, rejectWithValue }) => {
    try {
      const data = await categoryAPI.deleteCategory(ID);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
const initialState = {
  singleCategory: {},
  categoriesList: [],
  hasError: "",
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    getCategoriesList: (state, action) => {
      state.categoriesList = action.payload;
    },
    setSingleCategory: (state, action) => {
      state.singleCategory = action.payload;
    },
    setErrorEmpty: (state) => {
      state.hasError = "";
    },
  },
  extraReducers: {
    [getAllCategoriesRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [createCategoryRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [updateCategoryRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [deleteCategoryRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [singleCategoryRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
  },
});
export const { getCategoriesList, setSingleCategory, setErrorEmpty } =
  categorySlice.actions;

export default categorySlice.reducer;
