import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import validationSchemas from "../../utils/validations/validationSchemas";
import CustomBtn from "../../components/CustomBtn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MessageModal from "../../shared/loader/MessageModal";
import { setMessage } from "../../features/appSlice";
import CustomInput from "../../components/CustomInput";
import {
  createTableRequest,
  singleTableRequest,
  updateTableRequest,
} from "../../features/tableSlice";

const AddTable = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.app.message);
  const loading = useSelector((state) => state.app.isLoading);
  const submitError = useSelector((state) => state.tables.hasError);
  const singleTable = useSelector((state) => state.tables.singleTable);

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/table-management");
  };

  const onCreate = (values) => {
    if (props.edit) {
      let dataWithId = { ...values, id: singleTable._id };
      dispatch(updateTableRequest(dataWithId));
    } else {
      dispatch(createTableRequest(values));
    }
  };

  useEffect(() => {
    if (props.edit) {
      dispatch(singleTableRequest(id));
    }
  }, [dispatch, props.edit, id]);

  React.useEffect(() => {
    if (message) {
      setTimeout(() => {
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!message ? (
        <div
          style={{ minHeight: "80vh" }}
          className="mt-4   bg-secoundry shadow-dhruv rounded dark:bg-darkmodeSecoundry "
        >
          <div className=" pb-8">
            <h2 className=" font-semibold lg:px-4 text-lg ml-4 pt-4 text-blue-500 dark:text-darkmodeFots ">
              {props.edit ? "Update Table" : "Create Table"}
            </h2>
            <Formik
              enableReinitialize
              initialValues={{
                tableName: (props.edit && singleTable.tableName) || "",
              }}
              validationSchema={validationSchemas.tableSchema}
              onSubmit={onCreate}
            >
              {({ errors, touched }) => (
                <Form autoComplete="off">
                  <div className={`flex justify-start items-center pt-4 `}>
                    <div className=" lg:w-1/3 w-full lg:px-4">
                      <div className={`px-4 w-full`}>
                        <CustomInput
                          errors={errors.tableName}
                          touched={touched.tableName}
                          title="Enter Table Name"
                          name="tableName"
                          placeholder="Enter Table Name"
                        />
                      </div>
                    </div>
                  </div>
                  <h6 className="text-xs text-red-600 px-6 lg:px-8">
                    Table Name must be table+Any Number eg table4
                  </h6>
                  <div className="px-3 py-4">
                    <CustomBtn
                      type="submit"
                      text={props.edit ? "Update Table" : "Create Table"}
                      loading={loading}
                    />
                  </div>
                  {submitError && (
                    <div className="p-3 pl-8 text-red-500">{submitError}</div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <MessageModal
          open={message}
          setOpen={clearNotificationMessage}
          message={message}
        />
      )}
    </>
  );
};

export default AddTable;
