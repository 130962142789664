import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { tagsAPI } from "../api/tagsAPI";
import { setMessage, appLoading } from "./appSlice";
// async thunk request to get calls list
export const getAllTagsRequest = createAsyncThunk(
  "tags/getAllTagsRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await tagsAPI.getAllTags();
      dispatch(getTagsList(list));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
export const createTagRequest = createAsyncThunk(
  "tags/createTagRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await tagsAPI.createNewTag(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updateTagRequest = createAsyncThunk(
  "tags/updateTagRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await tagsAPI.updateTag(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const singleTagRequest = createAsyncThunk(
  "tags/singleTagRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      const data = await tagsAPI.singleTag(input);
      dispatch(setSingleTag(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteTagRequest = createAsyncThunk(
  "tags/deleteTagRequest",
  async (ID, { dispatch, rejectWithValue }) => {
    try {
      const data = await tagsAPI.deleteTag(ID);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
const initialState = {
  singleTag: {},
  tagsList: [],
  hasError: "",
};

export const tagSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    getTagsList: (state, action) => {
      state.tagsList = action.payload;
    },
    setSingleTag: (state, action) => {
      state.singleTag = action.payload;
    },
    setErrorEmpty: (state) => {
      state.hasError = "";
    },
  },
  extraReducers: {
    [getAllTagsRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [createTagRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [updateTagRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [deleteTagRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [singleTagRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
  },
});
export const { getTagsList, setSingleTag, setErrorEmpty } = tagSlice.actions;

export default tagSlice.reducer;
