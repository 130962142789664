import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import validationSchemas from "../../utils/validations/validationSchemas";
import CustomBtn from "../../components/CustomBtn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MessageModal from "../../shared/loader/MessageModal";
import { setMessage } from "../../features/appSlice";
import CustomInput from "../../components/CustomInput";
import NormalDropDown from "../../components/NormalDropDown";
import {
  addGameRequest,
  setSingleGame,
  updateGameRequest,
} from "../../features/gameSlice";
import { gameAPI } from "../../api/gameAPI";
import { tableAPI } from "../../api/tableAPI";
import { managerAPI } from "../../api/managerAPI";
import ToastNotification from "../../shared/ToastNotification";
import { reverseString } from "../../utils/helpers/helper";

const AddNewGame = (props) => {
  let todayDate = new Date().toISOString().split("T")[0];
  let currentTime = new Date().toTimeString().substring(0, 5);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.app.message);
  const loading = useSelector((state) => state.app.isLoading);
  const submitError = useSelector((state) => state.managers.hasError);
  const singleGame = useSelector((state) => state.games.singleGame);
  const [touch1, setTouch1] = useState(false);
  const [touch2, setTouch2] = useState(false);
  const [touch3, setTouch3] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [table, setTable] = useState("");
  const [tableList, setTableList] = useState([]);
  const [userId, setUserId] = useState("");
  const [userList, setUserList] = useState(["admin"]);
  const [status, setStatus] = useState("");

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/games");
  };

  const onCreate = (values) => {
    if (!table) return setErrorMessage("Table name is required");
    if (!status) return setErrorMessage("Status is required");
    if (!userId) return setErrorMessage("Please select User Id to assign Game");
    let allValues = {
      fromDate: reverseString(values.fromDate),
      status: status === "active" ? "Y" : "N",
      toDate: reverseString(values.toDate),
      resultTime: values.resultTime,
      gameName: values.gameName,
      userId: userId,
      table,
      waitTime: values.waitTime,
      primary: values.primary,
    };
    if (props.edit) {
      let dataWithId = { ...allValues, id: singleGame._id };
      dispatch(updateGameRequest(dataWithId));
    } else {
      dispatch(addGameRequest(allValues));
    }
  };

  useEffect(() => {
    if (props.edit) {
      async function getSingleGame(gameid) {
        let single = await gameAPI.singleGame(gameid);
        dispatch(setSingleGame(single));
        setTable(single.table);
        setUserId(single.userId);
        setStatus(single.status === "Y" ? "active" : "disabled");
      }
      getSingleGame(id);
    }
  }, [dispatch, props.edit, id]);

  useEffect(() => {
    async function getSingleGame() {
      let tables = await tableAPI.getAllTables();
      let managers = await managerAPI.getAllManagers();
      let tableArray =
        tables && tables.length > 0 && tables.map((i) => i.tableName);
      let managerArray =
        managers && managers.length > 0 && managers.map((i) => i.userId);
      setTableList(tableArray);
      setUserList(["admin", ...managerArray]);
    }
    getSingleGame();
  }, []);

  React.useEffect(() => {
    if (message) {
      setTimeout(() => {
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!message ? (
        <div
          style={{ minHeight: "80vh" }}
          className="mt-4 rounded bg-secoundry shadow-dhruv dark:bg-darkmodeSecoundry"
        >
          <ToastNotification
            message={errorMessage}
            open={errorMessage}
            setOpen={() => setErrorMessage("")}
          />
          <div className="pb-8 ">
            <h2 className="px-4 pt-4 ml-4 text-lg font-semibold text-blue-500 dark:text-darkmodeFots">
              {props.edit ? "Update Game" : "Create Game"}
            </h2>

            <Formik
              enableReinitialize
              initialValues={{
                gameName: (props.edit && singleGame.gameName) || "",
                resultTime:
                  (props.edit && singleGame.resultTime) || currentTime,
                waitTime: (props.edit && singleGame.waitTime) || currentTime,
                primary: (props.edit && singleGame.primary) || false,
                fromDate:
                  (props.edit && reverseString(singleGame.fromDate)) ||
                  todayDate,
                toDate:
                  (props.edit && reverseString(singleGame.toDate)) || todayDate,
              }}
              validationSchema={validationSchemas.gameValidation}
              onSubmit={onCreate}
            >
              {({ errors, touched, values }) => (
                <Form autoComplete="off">
                  <div
                    className={`flex justify-start md:flex-row flex-col items-center pt-4 `}
                  >
                    <div className="w-full px-4 lg:w-2/3">
                      <div className={`px-4 w-full`}>
                        <CustomInput
                          errors={errors.gameName}
                          touched={touched.gameName}
                          title="Game Name"
                          name="gameName"
                          placeholder="Enter Game Name"
                        />
                      </div>
                    </div>

                    <div className="w-full px-8 mb-5 lg:w-2/3 lg:px-4 ">
                      <NormalDropDown
                        text="sm" // text-xl text-xs
                        width="w-full" // w-48 , w-1/2 w-full
                        name="select" // name
                        array={[...userList]} // array to iterate
                        py="[6px]" // padding top & bottom
                        touch={touch1} // touch
                        setTouch={setTouch1} // set
                        label={"Client"}
                        placeholderText="select client"
                        defaultValue="Select Client"
                        value={userId}
                        setValue={setUserId}
                        objProperty="userId"
                      />
                    </div>
                    <div className="w-full px-4 mb-4 lg:w-2/3">
                      <div className={`px-4 w-full`}>
                        <div>
                          <div className="mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                            Valid from Date
                          </div>
                          <div className="text-center ">
                            <Field
                              name="fromDate"
                              type="date"
                              className={`${
                                errors.fromDate && touched.fromDate
                                  ? "border-red-500"
                                  : "border-gray-300"
                              }   outline-none  dark:text-gray-300 border rounded py-2 font-normal transition-all duration-300 focus:border-indigo-500 dark:border-gray-500 px-3 w-full text-sm text-gray-700 caret-indigo-600 dark:bg-darkmodeSecoundry `}
                            />
                          </div>
                          {errors.fromDate && touched.fromDate ? (
                            <div className="pl-4 text-xs text-red-500 ">
                              {errors.fromDate}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="w-full px-4 mb-4 lg:w-2/3">
                      <div className={`px-4 w-full`}>
                        <div>
                          <div className="mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                            Valid To Date
                          </div>
                          <div className="text-center ">
                            <Field
                              name="toDate"
                              type="date"
                              className={`${
                                errors.toDate && touched.toDate
                                  ? "border-red-500"
                                  : "border-gray-300"
                              }   outline-none  dark:text-gray-300 border rounded py-2 font-normal transition-all duration-300 focus:border-indigo-500 dark:border-gray-500 px-3 w-full text-sm text-gray-700 caret-indigo-600 dark:bg-darkmodeSecoundry `}
                            />
                          </div>
                          {errors.toDate && touched.toDate ? (
                            <div className="pl-4 text-xs text-red-500 ">
                              {errors.toDate}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`flex justify-start md:flex-row flex-col items-center lg:pt-4 w-full lg:w-11/12`}
                  >
                    <div className="w-full px-4 mb-4 lg:w-2/3">
                      <div className={`px-4 w-full`}>
                        <div>
                          <div className="mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                            Result Time
                          </div>
                          <div className="text-center ">
                            <Field
                              name="resultTime"
                              type="time"
                              className={`${
                                errors.resultTime && touched.resultTime
                                  ? "border-red-500"
                                  : "border-gray-300"
                              }   outline-none  dark:text-gray-300 border rounded py-2 font-normal transition-all duration-300 focus:border-indigo-500 dark:border-gray-500 px-3 w-full text-sm text-gray-700 caret-indigo-600 dark:bg-darkmodeSecoundry `}
                            />
                          </div>
                          {errors.resultTime && touched.resultTime ? (
                            <div className="pl-4 text-xs text-red-500 ">
                              {errors.resultTime}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="w-full px-4 mb-4 lg:w-2/3">
                      <div className={`px-4 w-full`}>
                        <div>
                          <div className="mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                            Wait Time
                          </div>
                          <div className="text-center ">
                            <Field
                              name="waitTime"
                              type="time"
                              className={`${
                                errors.waitTime && touched.waitTime
                                  ? "border-red-500"
                                  : "border-gray-300"
                              }   outline-none  dark:text-gray-300 border rounded py-2 font-normal transition-all duration-300 focus:border-indigo-500 dark:border-gray-500 px-3 w-full text-sm text-gray-700 caret-indigo-600 dark:bg-darkmodeSecoundry `}
                            />
                          </div>
                          {errors.waitTime && touched.waitTime ? (
                            <div className="pl-4 text-xs text-red-500 ">
                              {errors.waitTime}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="w-full px-8 mb-5 lg:w-2/3 ">
                      <NormalDropDown
                        text="sm" // text-xl text-xs
                        width="w-full" // w-48 , w-1/2 w-full
                        name="select" // name
                        array={tableList} // array to iterate
                        py="[6px]" // padding top & bottom
                        touch={touch2} // touch
                        setTouch={setTouch2} // set
                        label={"Table"}
                        placeholderText="table"
                        defaultValue="Select Table"
                        value={table}
                        setValue={setTable}
                        objProperty="tableName"
                      />
                    </div>
                    <div className="w-full px-8 mb-5 lg:w-2/3 lg:px-4 ">
                      <NormalDropDown
                        text="sm" // text-xl text-xs
                        width="w-full" // w-48 , w-1/2 w-full
                        name="select" // name
                        array={["active", "disabled"]} // array to iterate
                        py="[6px]" // padding top & bottom
                        touch={touch3} // touch
                        setTouch={setTouch3} // set
                        label={"Status"}
                        placeholderText="Status"
                        defaultValue="Select Status"
                        value={status} //
                        setValue={setStatus} //
                      />
                    </div>
                    <div className="text-center ">
                      <div className="mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                        Primary
                      </div>
                      <Field
                        name="primary"
                        type="checkbox"
                        className={`${
                          errors.primary && touched.primary
                            ? "border-red-500"
                            : "border-gray-300"
                        }   outline-none  dark:text-gray-300 border rounded py-2 font-normal transition-all duration-300 focus:border-indigo-500 dark:border-gray-500 px-3 w-full text-sm text-gray-700 caret-indigo-600 dark:bg-darkmodeSecoundry `}
                      />
                    </div>
                  </div>
                  <div className="px-3 py-4">
                    <CustomBtn
                      type="submit"
                      text={props.edit ? "Update Game" : "Create Game"}
                      loading={loading}
                    />
                  </div>
                  {submitError && (
                    <div className="p-3 pl-8 text-red-500">{submitError}</div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <MessageModal
          open={message}
          setOpen={clearNotificationMessage}
          message={message}
        />
      )}
    </>
  );
};

export default AddNewGame;
