export const markdownStyleObj = {
  p: ({ node, ...props }) => (
    <p
      style={{
        fontSize: "1.3rem",
        lineHeight: "2rem",
        maxWidth: "85vw",
      }}
      {...props}
    ></p>
  ),
  a: ({ node, ...props }) => (
    //eslint-disable-next-line
    <a
      target="_blank"
      style={{
        color: "#2734f4",
        fontWeight: 600,
      }}
      {...props}
    ></a>
  ),
  h1: ({ node, ...props }) => (
    //eslint-disable-next-line
    <h1
      style={{
        color: "#116466",
        fontWeight: "bold",
        fontSize: "2.5rem",
        lineHeight: "2rem",
      }}
      {...props}
    ></h1>
  ),
  //eslint-disable-next-line
  h2: ({ node, ...props }) => (
    //eslint-disable-next-line
    <h2
      style={{
        color: "#116466",
        fontWeight: "bold",
        fontSize: "1.5rem",
        lineHeight: "2rem",
      }}
      {...props}
    ></h2>
  ),
  //eslint-disable-next-line
  h3: ({ node, ...props }) => (
    //eslint-disable-next-line
    <h2
      style={{
        color: "#116466",
        fontWeight: "bold",
        fontSize: "1.25rem",
        lineHeight: "1.75rem",
      }}
      {...props}
    ></h2>
  ),
  ul: ({ node, ...props }) => (
    <ul
      style={{
        listStyleType: "disc",
        paddingLeft: "10px",
        paddingTop: "8px",
        paddingBottom: "8px",
        fontSize: "1.2rem",
        fontWeight: 500,
      }}
      {...props}
    ></ul>
  ),
  li: ({ node, ...props }) => (
    <li
      style={{
        paddingBottom: "6px",
      }}
      {...props}
    ></li>
  ),
  strong: ({ node, ...props }) => (
    <strong
      style={{
        color: "#116466",
        fontWeight: "bold",
      }}
      {...props}
    ></strong>
  ),
  blockquote: ({ node, ...props }) => (
    <blockquote
      style={{
        borderLeft: "solid 4px #9e9e9e",
        color: "#116466",
        paddingLeft: "1rem",
        fontStyle: "italic",
        marginTop: "12px",
        marginBottom: "1rem",
      }}
      {...props}
    ></blockquote>
  ),
  img: ({ node, ...props }) => (
    <img
      style={{
        borderRadius: "5px",
        marginTop: "6px",
        marginBottom: "6px",
        maxWidth: "60vw",
        width: "100%",
      }}
      alt="relative text about this"
      {...props}
    ></img>
  ),
};
