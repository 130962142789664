import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { MdWarningAmber } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import CustomInput from "../../components/CustomInput";
import validationSchemas from "../../utils/validations/validationSchemas";
import TextEditor from "../../components/editor/TextEditor";
import CustomBtn from "../../components/CustomBtn";
import {
  setErrorEmpty,
  updateBlogRequest,
  setSingleBlog,
} from "../../features/blogSlice";
import { setMessage, appLoading } from "../../features/appSlice";
import MessageModal from "../../shared/loader/MessageModal";
import { getAllCategoriesRequest } from "../../features/categorySlice";
import PillsDropDown from "../../components/PillsDropDown";
import CategoriesDropDown from "../../components/editor/CategoriesDropDown";
import { getAllTagsRequest } from "../../features/tagSlice";
import ToastNotification from "../../shared/ToastNotification";
import { blogImageUploader, tagExtract } from "../../utils/helpers/helper";

const UpdateBlog = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const loading = useSelector((state) => state.app.isLoading);
  const message = useSelector((state) => state.app.message);
  const singleBlogDetail = useSelector((state) => state.blogs.singleBlog);
  const blogError = useSelector((state) => state.blogs.blogError);
  const categoryArray = useSelector((state) => state.categories.categoriesList);
  const tagsArray = useSelector((state) => state.tags.tagsList);
  const [file, setFile] = useState({});
  const [type, setType] = useState("upload");
  const [url, setUrl] = useState("");
  const [input, setInput] = useState("");
  const [imageUploadErrorMessage, setImageErrorMessage] = useState("");
  const [imagesPreview, setImagesPreview] = useState("");
  const [textValue, editText] = useState("");
  const [category, setSelectedCategory] = useState("");
  const [pills, setTagPills] = useState([]); // To handle image upload

  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setImagesPreview("");
      return setImageErrorMessage("File not exist.");
    }
    if (file.size > 1024 * 1024) {
      setImagesPreview("");
      return setImageErrorMessage("Size too large!");
    }
    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      setImagesPreview("");
      return setImageErrorMessage("File format is incorrect.");
    }
    setFile(file);
    const reader = new FileReader();
    setImageErrorMessage("");
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImagesPreview(reader.result);
      }
    };

    reader.readAsDataURL(file);
  };

  // Submit Blog details synchronously uploading image first and then sending blog data to api
  const onSubmit = (values) => {
    if (!category) return dispatch(setErrorEmpty("Please select category"));
    if (!pills.length) {
      return dispatch(setErrorEmpty("Atleast one tag required"));
    }
    if (!textValue) return dispatch(setErrorEmpty("Blog content is Required"));
    dispatch(appLoading(true));
    const editorData = {
      description: textValue,
    };
    const tagsData = {
      tags: pills.toString(),
    };
    const categoryData = {
      category: category,
    };
    const compeleteObj = {
      ...values,
      ...editorData,
      ...tagsData,
      ...categoryData,
    };

    let blogId = { id: singleBlogDetail._id };
    let compeleteObjWithId = { ...compeleteObj, ...blogId };
    if (!file.name && !url) {
      let images = singleBlogDetail.images;
      let dataWithExistingImage = {
        ...compeleteObj,
        images,
        ...blogId,
      };
      dispatch(updateBlogRequest(dataWithExistingImage));
      dispatch(appLoading(false));
    }
    if (!file.name && url) {
      let images = { url };
      let dataWithExistingImage = {
        ...compeleteObj,
        images,
        ...blogId,
      };
      dispatch(updateBlogRequest(dataWithExistingImage));
      dispatch(appLoading(false));
    }
    if (file.name && !url) {
      blogImageUploader(
        file,
        compeleteObjWithId,
        dispatch,
        updateBlogRequest,
        appLoading
      );
    }
    dispatch(appLoading(false));
  };

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/blogs");
  };

  //   useEffect(() => {
  //  dispatch(singleBlogRequest(id));
  //   }, [dispatch, id]);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/blogs/${id}`).then((result) => {
      dispatch(setSingleBlog(result.data));
      const existingTags = tagExtract(result.data.tags);
      setTagPills(existingTags);
      editText(result.data.description);
      setSelectedCategory(result.data.category);
    });
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(getAllCategoriesRequest());
    dispatch(getAllTagsRequest());
  }, [dispatch]);

  React.useEffect(() => {
    if (message) {
      setTimeout(() => {
        const clearNotificationMessage = () => {
          dispatch(setMessage(""));
          navigate("/blogs");
        };
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!message ? (
        <div className=" transition-all ease-in-out mt-4 py-3 mb-2 dark:bg-darkmodeSecoundry bg-secoundry shadow-dhruv rounded">
          {blogError && (
            <ToastNotification
              message={blogError}
              open={blogError}
              setOpen={() => dispatch(setErrorEmpty(""))}
            />
          )}
          <h2 className=" ml-6 pt-1 pb-4 text-xl text-indigo-500 dark:text-darkmodeFots ">
            Update Blog
          </h2>
          {singleBlogDetail?.images && (
            <div className="mr-4 ml-6 mb-4 inline-flex items-center">
              <img
                className="max-w-[8rem] max-h-[10rem] rounded-lg mt-2 border border-gray-200"
                src={singleBlogDetail.images.url}
                alt="update blog sdd refrence"
              />
              <span className="text-gray-600 dark:text-gray-200 pl-4">
                Existed Image
              </span>
            </div>
          )}
          <div className="flex items-start mx-6 cursor-pointer pb-4 space-x-2  sm:justify-start flex-nowrap text-gray-800">
            <p
              onClick={() => {
                setUrl("");
                setType("upload");
              }}
              className={`text-xs flex-shrink-0 px-2 py-2 ${
                type === "upload" ? "border-indigo-600" : "border-gray-300"
              } border-b-4  dark:text-gray-300 text-gray-600`}
            >
              Upload Cover Image
            </p>
            <p
              onClick={() => {
                setFile({});
                setImagesPreview("");
                setType("url");
              }}
              className={`text-xs ${
                type === "url" ? "border-indigo-600" : "border-gray-300"
              } flex-shrink-0 px-2 py-2 border-b-4 dark:text-gray-300 border-gray-300 text-gray-600`}
            >
              Unsplash/Pexel/Cloudinary URL
            </p>
          </div>
          <div className="flex flex-row mx-6 mb-4  rounded">
            <h2 className="text-sm text-gray-500 dark:text-darkmodeFots">
              <span className="text-blue-600 font-medium">
                If you want to update image then Upload New Image or URL (New
                Image will be replaced)
              </span>
            </h2>
          </div>
          {type === "upload" && (
            <div className=" flex items-center ml-6">
              {!imagesPreview && (
                <div className="min-w-[12rem] min-h-[6rem] bg-gray-200 rounded-md mr-4 relative">
                  <div className="absolute top-1/3 left-1/3 right-2/3 bottom-2/ w-[5rem] text-blue-500/50 font-bold text-sm">
                    No Image
                  </div>
                </div>
              )}
              {imagesPreview && (
                <div className="mr-4">
                  <img
                    className="max-w-[8rem] max-h-[10rem] rounded-lg mt-2 border border-gray-200"
                    src={imagesPreview}
                    alt="update blog refrence"
                  />
                </div>
              )}

              <div className="ml-4">
                <label className=" text-xs text-gray-500 dark:text-darkmodeFots">
                  Best Image Resolution 1280x720, Maximum allowed image size 1
                  MB
                </label>
                <div className=" rounded mt-3 dark:border-gray-500 relative">
                  <input
                    type="file"
                    name="file"
                    onChange={handleUpload}
                    className=" dark:bg-darkmodeSecoundry outline-none z-30 border dark:text-gray-400 dark:font-semibold"
                  />
                </div>
              </div>
              {imageUploadErrorMessage && (
                <div className="ml-12 px-12 py-2 text-sm rounded-md bg-red-500/90 text-white">
                  {imageUploadErrorMessage}
                </div>
              )}
            </div>
          )}

          {type === "url" && (
            <div className="flex items-center ml-6 ">
              <div className="max-w-[12rem] max-h-[6rem] min-w-[12rem] min-h-[6rem] bg-gray-200 rounded-md mr-4 relative">
                {!url && (
                  <div className="absolute top-1/3 left-1/3 right-2/3 bottom-2/ w-[5rem] text-blue-500/50 font-bold text-sm">
                    No Image
                  </div>
                )}
                {url && (
                  <img
                    className="w-full max-h-[6rem] rounded-md"
                    src={url}
                    alt="url"
                  />
                )}
              </div>
              <div className="flex items-center w-full">
                <input
                  type="text"
                  onChange={(e) => setInput(e.target.value)}
                  className=" outline-none dark:text-gray-300  border rounded font-normal transition-all duration-300 focus:border-indigo-500 dark:border-gray-500 px-3 py-2 w-1/2 text-sm text-gray-700 caret-indigo-600 dark:bg-darkmodeSecoundry"
                  placeholder="Unsplash / Pexel / Cloudinary URL"
                />
                <button
                  onClick={() => setUrl(input)}
                  className="bg-indigo-500 hover:bg-opacity-80 text-sm disabled:bg-gray-400 disabled:text-gray-100 py-2 px-4 rounded text-white  ml-5 outline-none hover:scale-105 transform"
                >
                  Upload
                </button>
              </div>
            </div>
          )}

          <Formik
            enableReinitialize
            initialValues={{
              title: singleBlogDetail.title || "",
            }}
            validationSchema={validationSchemas.blogValidationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched, values }) => (
              <Form>
                <div className=" flex justify-start items-center my-4">
                  <div className=" ml-5 w-full">
                    <CustomInput
                      title="Title"
                      name="title"
                      placeholder="Enter title"
                    />
                    {errors.title && touched.title && (
                      <div className="text-red-500 text-sm flex items-center mt-2 ml-1">
                        <MdWarningAmber className="mr-1 text-base" />
                        <div> {errors.title}</div>
                      </div>
                    )}
                  </div>
                  <div
                    className={`${
                      errors.title && touched.title && "mb-7"
                    } mx-5 w-full `}
                  >
                    <CategoriesDropDown
                      title="Category"
                      ArrayList={categoryArray}
                      setSelectedCategory={setSelectedCategory}
                      category={category}
                      edit={props.edit}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div className=" mx-5 w-full">
                    <PillsDropDown
                      title="Tags"
                      ArrayList={tagsArray}
                      setTagPills={setTagPills}
                      pills={pills}
                      edit={props.edit}
                      existingTags={
                        singleBlogDetail !== undefined && singleBlogDetail.tags
                      }
                    />
                  </div>
                </div>
                <div className=" px-5 mt-6">
                  <label className=" font-medium text-sm text-gray-500">
                    Content
                  </label>
                  <TextEditor textValue={textValue} editText={editText} />
                </div>
                <CustomBtn
                  disabled={loading}
                  loading={loading}
                  type="submit"
                  text={"Update Now"}
                />
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <MessageModal
          open={message}
          setOpen={clearNotificationMessage}
          message={message}
        />
      )}
    </>
  );
};

export default UpdateBlog;
