import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MdWarningAmber } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import CustomInput from "../../components/CustomInput";
import validationSchemas from "../../utils/validations/validationSchemas";
import TextEditor from "../../components/editor/TextEditor";
import CustomBtn from "../../components/CustomBtn";
import { createBlogRequest, setErrorEmpty } from "../../features/blogSlice";
import { setMessage, appLoading } from "../../features/appSlice";
import MessageModal from "../../shared/loader/MessageModal";
import { getAllGamesRequest } from "../../features/gameSlice";
import PillsDropDown from "../../components/PillsDropDown";
import CategoriesDropDown from "../../components/editor/CategoriesDropDown";
import { getAllTagsRequest } from "../../features/tagSlice";
import ToastNotification from "../../shared/ToastNotification";
import { useLocalStorageState } from "../../hooks/useLocalStorage";

const CreateBlog = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.app.isLoading);
  const message = useSelector((state) => state.app.message);
  const singleBlogDetail = useSelector((state) => state.blogs.singleBlog);
  const blogError = useSelector((state) => state.blogs.blogError);
  const categoryArray = useSelector((state) => state.games.gamesList);
  const tagsArray = useSelector((state) => state.tags.tagsList);
  const [textValue, editText] = useLocalStorageState("markdown", "");
  const [category, setSelectedCategory] = useState("");
  const [pills, setTagPills] = useState([]); // To handle image upload

  // Submit Blog details synchronously uploading image first and then sending blog data to api
  const onSubmit = (values) => {
    if (!category) return dispatch(setErrorEmpty("Please select category"));
    if (!pills.length) {
      return dispatch(setErrorEmpty("Atleast one tag required"));
    }
    if (!textValue) return dispatch(setErrorEmpty("Blog content is Required"));
    dispatch(appLoading(true));
    const editorData = {
      description: textValue,
    };
    const tagsData = {
      tags: pills.toString(),
    };
    const categoryData = {
      category: category,
    };
    const compeleteObj = {
      ...values,
      ...editorData,
      ...tagsData,
      ...categoryData,
    };

    dispatch(createBlogRequest(compeleteObj));
    localStorage.removeItem("markdown");
    dispatch(appLoading(false));
  };

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/blogs");
  };

  useEffect(() => {
    dispatch(getAllGamesRequest());
    dispatch(getAllTagsRequest());
  }, [dispatch]);

  React.useEffect(() => {
    if (message) {
      setTimeout(() => {
        const clearNotificationMessage = () => {
          dispatch(setMessage(""));
          navigate("/blogs");
        };
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!message ? (
        <div className="py-3 mt-4 mb-2 transition-all ease-in-out rounded dark:bg-darkmodeSecoundry bg-secoundry shadow-dhruv">
          {blogError && (
            <ToastNotification
              message={blogError}
              open={blogError}
              setOpen={() => dispatch(setErrorEmpty(""))}
            />
          )}
          <h2 className="pt-1 pb-4 ml-6 text-xl text-indigo-500 dark:text-darkmodeFots">
            {props.edit ? "Update Blog" : "Create New Blog"}
          </h2>

          <Formik
            enableReinitialize
            initialValues={{
              title: singleBlogDetail.title || "",
            }}
            validationSchema={validationSchemas.blogValidationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched, values }) => (
              <Form>
                <div className="flex items-center justify-start my-4 ">
                  <div className="w-full ml-5 ">
                    <CustomInput
                      title="Title"
                      name="title"
                      placeholder="Enter title"
                    />
                    {errors.title && touched.title && (
                      <div className="flex items-center mt-2 ml-1 text-sm text-red-500">
                        <MdWarningAmber className="mr-1 text-base" />
                        <div> {errors.title}</div>
                      </div>
                    )}
                  </div>
                  <div
                    className={`${
                      errors.title && touched.title && "mb-7"
                    } mx-5 w-full `}
                  >
                    <CategoriesDropDown
                      title="Category"
                      ArrayList={[
                        { gameName: "index", slug: "index" },
                        ...categoryArray,
                      ]}
                      setSelectedCategory={setSelectedCategory}
                      category={category}
                      edit={props.edit}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div className="w-full mx-5 ">
                    <PillsDropDown
                      title="Tags"
                      ArrayList={tagsArray}
                      setTagPills={setTagPills}
                      pills={pills}
                      edit={props.edit}
                      existingTags={
                        singleBlogDetail !== undefined && singleBlogDetail.tags
                      }
                    />
                  </div>
                </div>
                <div className="px-5 mt-6 ">
                  <label className="text-sm font-medium text-gray-500 ">
                    Content
                  </label>
                  <TextEditor textValue={textValue} editText={editText} />
                </div>
                <CustomBtn
                  disabled={loading}
                  loading={loading}
                  type="submit"
                  text={props.edit ? "Update" : "Create"}
                />
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <MessageModal
          open={message}
          setOpen={clearNotificationMessage}
          message={message}
        />
      )}
    </>
  );
};

export default CreateBlog;
