import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { deleteCategoryRequest } from "../../features/categorySlice";
import DeleteModal from "../../components/DeleteModal";
import { setMessage } from "../../features/appSlice";

const DeleteCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const deletemessage = useSelector((state) => state.app.message);

  const deleteRequest = () => {
    dispatch(deleteCategoryRequest(id));
  };

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/categories");
  };

  React.useEffect(() => {
    if (deletemessage) {
      setTimeout(() => {
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [deletemessage]);

  return (
    <DeleteModal
      message={deletemessage}
      cancelLink="/categories"
      deleteFunction={deleteRequest}
    />
  );
};

export default DeleteCategory;
