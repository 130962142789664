import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export const notificationAPI = {
  getAllNotifications: () =>
    axios.get(`${baseURL}/notifications`).then((res) => res.data),
  createNewNotification: (article) =>
    axios.post(`${baseURL}/notifications`, article).then((res) => res.data),
  singleNotification: (id) =>
    axios.get(`${baseURL}/notifications/${id}`).then((res) => res.data),
  updateNotification: async (details) => {
    const { id, ...rest } = details;
    return await axios
      .put(`${baseURL}/notifications/${id}`, rest)
      .then((res) => res.data);
  },
  deleteNotification: (id) =>
    axios.delete(`${baseURL}/notifications/${id}`).then((res) => res.data),
};
