import axios from "axios";

export const webStoryAPI = {
  getStoriesfromLast24Hours: async () => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/last-24-hours-web-stories`)
      .then((res) => res.data);
  },
  allStoriesCount: async () => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/all-stories-count`)
      .then((res) => res.data);
  },

  getAllStories: async () => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/web-stories`)
      .then((res) => res.data);
  },
  createNewStory: async (article) => {
    return await axios
      .post(`${process.env.REACT_APP_API_URL}/create-web-story`, article)
      .then((res) => res.data);
  },
  singleStory: async (id) => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/web-stories/${id}`)
      .then((res) => res.data);
  },
  updateStory: async (details) => {
    const { id, ...rest } = details;
    return await axios
      .put(`${process.env.REACT_APP_API_URL}/web-stories/${id}`, rest)
      .then((res) => res.data);
  },
  deleteStory: async (id) => {
    return await axios
      .delete(`${process.env.REACT_APP_API_URL}/web-stories/${id}`)
      .then((res) => res.data);
  },
};
