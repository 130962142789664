import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TableHeader from "../../components/TableHeader";
import { performSearchQuery } from "../../utils/helpers/helper";
import DynamicTable from "../../components/DynamicTable";
import Pagination from "../../components/Pagination";
import {
  getAllResultsRequest,
  setErrorEmpty,
} from "../../features/resultSlice";
import allTableHeaders from "../../tableHeaders/AllTableHeaders";
import { setMessage } from "../../features/appSlice";

const Resultslist = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(300);
  const resultsArray = useSelector((state) => state.results.resultsList);
  const searchArray = performSearchQuery(
    resultsArray,
    search,
    "resultDate",
    "gameName"
  );
  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentResultsToMap =
    resultsArray &&
    resultsArray.length > 0 &&
    resultsArray.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    dispatch(getAllResultsRequest());
    dispatch(setMessage(""));
    dispatch(setErrorEmpty());
  }, [dispatch]);
  useEffect(() => {}, [postsPerPage]);
  return (
    <div className="dark:bg-tableclmn bg-secoundry shadow-dhruv mt-4 font-semibold text-iconColor">
      <TableHeader
        ButtonName="Publish Result"
        routs="/results/publish-result"
        setSearch={setSearch}
        postsPerPage={postsPerPage}
        setPostsPerPage={setPostsPerPage}
        search={search}
        heading={"Result's List"}
        dataArray={resultsArray}
      />
      {resultsArray && resultsArray.length > 0 ? (
        <div>
          <DynamicTable
            mappingDataArray={currentResultsToMap}
            tableHeaderArray={allTableHeaders.resultTableHeader}
            tableActionHeader={true}
            search={search}
            searchArray={searchArray}
            updateLinkString="/results/update-result"
            updateLinkTerm="id"
            deleteLinkString="/results/delete-result"
            deleteLinkTerm="id"
          />
          <Pagination
            currentPage={currentPage}
            postsPerPage={postsPerPage}
            totalPosts={resultsArray && resultsArray.length}
            paginate={paginate}
          />
        </div>
      ) : (
        <div className="flex py-32">
          <div className="m-auto ">
            <p className="text-sm font-normal text-gray-700 mb-2 text-center">
              No Records
            </p>
            <Link to="/results/publish-result">
              <button className="bg-yellow-600 text-sm px-6 py-1 text-white rounded-sm hover:bg-yellow-500">
                Publish Result
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Resultslist;
