import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { managerAPI } from "../api/managerAPI";
import { setMessage, appLoading } from "./appSlice";
// async thunk request to get calls list
export const getAllManagersRequest = createAsyncThunk(
  "managers/getAllManagersRequest",
  async (all, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await managerAPI.getAllManagers();
      dispatch(getManagersList(list));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
export const addNewManagerRequest = createAsyncThunk(
  "managers/addNewManagerRequest",
  async (createData, { dispatch, rejectWithValue }) => {
    try {
      const data = await managerAPI.addNewManager(createData);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const singleManagerRequest = createAsyncThunk(
  "managers/singleManagerRequest",
  async (leadId, { dispatch, rejectWithValue }) => {
    try {
      const data = await managerAPI.singleManager(leadId);
      dispatch(setSingleManager(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteManagerRequest = createAsyncThunk(
  "managers/deleteManagerRequest",
  async (idToDelete, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await managerAPI.deleteManager(idToDelete);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updateManagerRequest = createAsyncThunk(
  "managers/updateManagerRequest",
  async (idToDelete, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await managerAPI.updateManager(idToDelete);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

const initialState = {
  managersList: [],
  singleManager: {},
  hasError: "",
};

export const managerSlice = createSlice({
  name: "managers",
  initialState,
  reducers: {
    getManagersList: (state, action) => {
      state.managersList = action.payload;
    },
    setSingleManager: (state, action) => {
      state.singleManager = action.payload;
    },
    setErrorEmpty: (state) => {
      state.hasError = "";
    },
  },
  extraReducers: {
    [getAllManagersRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [addNewManagerRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [singleManagerRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [deleteManagerRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [updateManagerRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
  },
});
export const { getManagersList, setSingleManager, setErrorEmpty } =
  managerSlice.actions;

export default managerSlice.reducer;
