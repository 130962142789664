import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { tableAPI } from "../api/tableAPI";
import { setMessage, appLoading } from "./appSlice";
// async thunk request to get calls list
export const getAllTablesRequest = createAsyncThunk(
  "tables/getAllTablesRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await tableAPI.getAllTables();
      dispatch(getTablesList(list));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
export const createTableRequest = createAsyncThunk(
  "tables/createTableRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await tableAPI.createNewTable(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updateTableRequest = createAsyncThunk(
  "tables/updateTableRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await tableAPI.updateTable(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const singleTableRequest = createAsyncThunk(
  "tables/singleTableRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      const data = await tableAPI.singleTable(input);
      dispatch(setSingleTable(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteTableRequest = createAsyncThunk(
  "tables/deleteTableRequest",
  async (ID, { dispatch, rejectWithValue }) => {
    try {
      const data = await tableAPI.deleteTable(ID);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
const initialState = {
  singleTable: {},
  tablesList: [],
  hasError: "",
};

export const tableSlice = createSlice({
  name: "tables",
  initialState,
  reducers: {
    getTablesList: (state, action) => {
      state.tablesList = action.payload;
    },
    setSingleTable: (state, action) => {
      state.singleTable = action.payload;
    },
    setErrorEmpty: (state) => {
      state.hasError = "";
    },
  },
  extraReducers: {
    [getAllTablesRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [createTableRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [updateTableRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [deleteTableRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [singleTableRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
  },
});
export const { getTablesList, setSingleTable, setErrorEmpty } =
  tableSlice.actions;

export default tableSlice.reducer;
