import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export const gameAPI = {
  getAllGames: async () => {
    return axios.get(`${baseURL}/games`).then((res) => res.data);
  },
  addNewGame: async (article) => {
    return await axios
      .post(`${baseURL}/games`, article)
      .then((res) => res.data);
  },
  singleGame: async (id) => {
    return await axios.get(`${baseURL}/games/${id}`).then((res) => res.data);
  },
  updateGame: async (details) => {
    const { id, ...rest } = details;
    return await axios
      .put(`${baseURL}/games/${id}`, rest)
      .then((res) => res.data);
  },
  updateChart: async (details) => {
    const { id, ...rest } = details;
    return await axios
      .put(`${baseURL}/update-chart/${id}`, rest)
      .then((res) => res.data);
  },
  deleteGame: async (id) => {
    return await axios.delete(`${baseURL}/games/${id}`).then((res) => res.data);
  },
};
