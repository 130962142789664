import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { adsAPI } from "../api/adsAPI";
import { setMessage, appLoading } from "./appSlice";
// async thunk request to get calls list
export const getAllAdsRequest = createAsyncThunk(
  "ads/getAllAdsRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await adsAPI.getAllAds();
      dispatch(getAdsList(list));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
export const createNewAdRequest = createAsyncThunk(
  "ads/createNewAdRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await adsAPI.createNewAd(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updateAdRequest = createAsyncThunk(
  "ads/updateAdRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await adsAPI.updateAd(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const singleAdRequest = createAsyncThunk(
  "ads/singleAdRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      const data = await adsAPI.singleAd(input);
      dispatch(setSingleAd(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteAdRequest = createAsyncThunk(
  "ads/deleteAdRequest",
  async (ID, { dispatch, rejectWithValue }) => {
    try {
      const data = await adsAPI.deleteAd(ID);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
const initialState = {
  singleAd: {},
  adsList: [],
  hasError: "",
};

export const adSlice = createSlice({
  name: "ads",
  initialState,
  reducers: {
    getAdsList: (state, action) => {
      state.adsList = action.payload;
    },
    setSingleAd: (state, action) => {
      state.singleAd = action.payload;
    },
    setErrorEmpty: (state) => {
      state.hasError = "";
    },
  },
  extraReducers: {
    [getAllAdsRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [createNewAdRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [updateAdRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [deleteAdRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [singleAdRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
  },
});
export const { getAdsList, setSingleAd, setErrorEmpty } = adSlice.actions;

export default adSlice.reducer;
