import { configureStore } from "@reduxjs/toolkit";
import appReducer from "../features/appSlice";
import managerReducer from "../features/managerSlice";
import faqReducer from "../features/faqSlice";
import loginReducer from "../features/loginSlice";
import adminReducer from "../features/adminUserSlice";
import paginationReducer from "../features/paginationSlice";
import blogReducer from "../features/blogSlice";
import notificationsReducer from "../features/notificationSlice";
import categoryReducer from "../features/categorySlice";
import tableReducer from "../features/tableSlice";
import adReducer from "../features/adSlice";
import resultReducer from "../features/resultSlice";
import gameReducer from "../features/gameSlice";
import tagReducer from "../features/tagSlice";
import termReducer from "../features/termSlice";
import storyReducer from "../features/webStorySlice";
import imagesReducer from "../features/UploadedImageSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    managers: managerReducer,
    faqs: faqReducer,
    login: loginReducer,
    user: adminReducer,
    pages: paginationReducer,
    blogs: blogReducer,
    categories: categoryReducer,
    tables: tableReducer,
    tags: tagReducer,
    games: gameReducer,
    ads: adReducer,
    results: resultReducer,
    terms: termReducer,
    webStories: storyReducer,
    uploadedImages: imagesReducer,
    notifications: notificationsReducer,
  },
});
