import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notificationAPI } from "../api/notificationAPI";
import { setMessage, appLoading } from "./appSlice";
// async thunk request to get calls list
export const getAllNotificationsRequest = createAsyncThunk(
  "notifications/getAllNotificationsRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await notificationAPI.getAllNotifications();
      dispatch(getNotificationsList(list));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
export const createNotificationRequest = createAsyncThunk(
  "notifications/createNotificationRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await notificationAPI.createNewNotification(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updateNotificationRequest = createAsyncThunk(
  "notifications/updateNotificationRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await notificationAPI.updateNotification(input);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const singleNotificationRequest = createAsyncThunk(
  "notifications/singleNotificationRequest",
  async (input, { dispatch, rejectWithValue }) => {
    try {
      const data = await notificationAPI.singleNotification(input);
      dispatch(setSingleNotification(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteNotificationRequest = createAsyncThunk(
  "notifications/deleteNotificationRequest",
  async (ID, { dispatch, rejectWithValue }) => {
    try {
      const data = await notificationAPI.deleteNotification(ID);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
const initialState = {
  singleNotification: {},
  notificationList: [],
  hasError: "",
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    getNotificationsList: (state, action) => {
      state.notificationList = action.payload;
    },
    setSingleNotification: (state, action) => {
      state.singleNotification = action.payload;
    },
    setErrorEmpty: (state) => {
      state.hasError = "";
    },
  },
  extraReducers: {
    [getAllNotificationsRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [createNotificationRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [updateNotificationRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [deleteNotificationRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [singleNotificationRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
  },
});
export const { getNotificationsList, setSingleNotification, setErrorEmpty } =
  notificationSlice.actions;

export default notificationSlice.reducer;
